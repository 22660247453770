import React from 'react';
import { Form } from 'react-bootstrap';
import { makeClasses } from '../helpers/object';
import { useDatatableWrapper } from './DatatableWrapper';
import { getNextCheckboxState } from '../helpers/checkbox';
import { useControlledStateSetter } from '../helpers/hooks';
/**
 * Renders the table body, which is a `tbody` tag along with the rest of the elements,
 * such as `tr` and `td` tags.
 */
export function TableBody({ labels, classes, rowProps, onRowClick: onRowClickProp, controlledProps, children }) {
    const { data, headers, onCheckboxChange: onCheckboxChangeContext, checkboxState: checkboxStateContext, filteredDataLength: filteredDataLengthContext, checkboxRefs } = useDatatableWrapper();
    useControlledStateSetter(controlledProps);
    let bodyContent;
    if (children) {
        if (typeof children === 'function') {
            bodyContent = children(data);
        }
        else {
            bodyContent = children;
        }
    }
    else {
        const body = [];
        const dataLength = data.length;
        const headersLength = headers.length;
        const onCheckboxChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onCheckboxChange) || onCheckboxChangeContext;
        const checkboxState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.checkboxState) || checkboxStateContext;
        const filteredDataLength = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filteredDataLength) || filteredDataLengthContext;
        if (dataLength > 0) {
            for (let rowIdx = 0; rowIdx < dataLength; rowIdx++) {
                const row = [];
                for (let colIdx = 0; colIdx < headersLength; colIdx++) {
                    const { cell, cellProps = {}, prop: rawProp, checkbox, alignment } = headers[colIdx];
                    const prop = rawProp.toString();
                    let value = '';
                    if (checkbox) {
                        // Render checkbox.
                        const idValue = data[rowIdx][checkbox.idProp];
                        const isSelected = checkboxState[prop].selected.has(idValue);
                        // Source for using visually hidden: https://www.w3.org/WAI/tutorials/forms/labels/#hiding-the-label-element.
                        value = (React.createElement(Form.Group, { controlId: `table-selection-${data[rowIdx][checkbox.idProp]}` },
                            React.createElement(Form.Label, { className: "visually-hidden" }, isSelected
                                ? `Remove ${idValue} from selection`
                                : `Add ${idValue} to selection`),
                            React.createElement(Form.Check, { type: "checkbox", name: "table-selection", value: data[rowIdx][checkbox.idProp], className: checkbox.className, checked: checkboxState[prop].selected.has(idValue), onChange: () => {
                                    onCheckboxChange({
                                        prop,
                                        idProp: checkbox.idProp,
                                        nextCheckboxState: getNextCheckboxState({
                                            checkboxState,
                                            data: data[rowIdx],
                                            idProp: checkbox.idProp,
                                            filteredDataLength,
                                            prop,
                                            type: isSelected ? 'remove' : 'add'
                                        }),
                                        checkboxRefs
                                    });
                                } })));
                    }
                    else {
                        // Render normally.
                        if (cell === undefined) {
                            value = data[rowIdx][prop];
                        }
                        else {
                            value = cell(data[rowIdx]);
                        }
                    }
                    row.push(React.createElement("td", { key: `col-${rowIdx}${colIdx}`, className: makeClasses('tbody-td', classes === null || classes === void 0 ? void 0 : classes.td, 
                        // Alignment.
                        {
                            'text-start': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'left',
                            'text-center': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'center',
                            'text-end': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'right'
                        }, typeof cellProps.className === 'function'
                            ? cellProps.className(data[rowIdx])
                            : cellProps.className), style: typeof cellProps.style === 'function'
                            ? cellProps.style(data[rowIdx])
                            : cellProps.style }, value));
                }
                body.push(React.createElement(TableRow, { key: rowIdx, rowData: data[rowIdx], rowProps: rowProps, classes: { td: classes === null || classes === void 0 ? void 0 : classes.td, tr: classes === null || classes === void 0 ? void 0 : classes.tr }, controlledProps: controlledProps, onRowClick: onRowClickProp }));
            }
        }
        else {
            body.push(React.createElement(EmptyTablePlaceholder, { key: "row-zero-length", noResultsLabel: labels === null || labels === void 0 ? void 0 : labels.noResults }));
        }
        bodyContent = body;
    }
    return (React.createElement("tbody", { className: makeClasses('tbody', classes === null || classes === void 0 ? void 0 : classes.tbody) }, bodyContent));
}
/**
 * `TableRow` component, as its name suggests, is a component to render a row of a table.
 * This component is exported so it is possible to compose the rows further. For example:
 *
 * ```
 * <TableBody>
 *   {
 *     data.map(row => (
 *       row.isLoading ? (
 *         <tr><td colSpan={headers.length}><Loading /></td></tr>
 *       ) : (
 *         <TableRow rowData={row} />
 *       )
 *     ))
 *   }
 * </TableBody>
 * ```
 *
 * The above snippet will render loading indicator for rows that don't have sufficient data to store yet.
 *
 * @param param0
 * @returns
 */
export function TableRow({ rowData, onRowClick: onRowClickProp, classes, controlledProps, rowProps }) {
    const { headers, onCheckboxChange: onCheckboxChangeContext, checkboxState: checkboxStateContext, filteredDataLength: filteredDataLengthContext, checkboxRefs } = useDatatableWrapper();
    const headersLength = headers.length;
    function onRowClick(event) {
        if (typeof onRowClickProp === 'function') {
            onRowClickProp(rowData, event);
        }
    }
    const onCheckboxChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onCheckboxChange) || onCheckboxChangeContext;
    const checkboxState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.checkboxState) || checkboxStateContext;
    const filteredDataLength = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filteredDataLength) || filteredDataLengthContext;
    const row = [];
    for (let colIdx = 0; colIdx < headersLength; colIdx++) {
        const { cell, cellProps = {}, prop: rawProp, checkbox, alignment } = headers[colIdx];
        const prop = rawProp.toString();
        let value = '';
        if (checkbox && checkboxState && onCheckboxChange) {
            // Render checkbox.
            const idValue = rowData[checkbox.idProp];
            const isSelected = checkboxState[prop].selected.has(idValue);
            // Source for using visually hidden: https://www.w3.org/WAI/tutorials/forms/labels/#hiding-the-label-element.
            value = (React.createElement(Form.Group, { controlId: `table-selection-${rowData[checkbox.idProp]}` },
                React.createElement(Form.Label, { className: "visually-hidden" }, isSelected
                    ? `Remove ${idValue} from selection`
                    : `Add ${idValue} to selection`),
                React.createElement(Form.Check, { type: "checkbox", name: "table-selection", value: rowData[checkbox.idProp], className: checkbox.className, checked: checkboxState[prop].selected.has(idValue), onChange: () => {
                        onCheckboxChange({
                            prop,
                            idProp: checkbox.idProp,
                            nextCheckboxState: getNextCheckboxState({
                                checkboxState,
                                data: rowData,
                                idProp: checkbox.idProp,
                                filteredDataLength,
                                prop,
                                type: isSelected ? 'remove' : 'add'
                            }),
                            checkboxRefs
                        });
                    } })));
        }
        else {
            // Render normally.
            if (cell === undefined) {
                value = rowData[prop];
            }
            else {
                value = cell(rowData);
            }
        }
        row.push(React.createElement("td", { key: `col-${colIdx}`, className: makeClasses('tbody-td', classes === null || classes === void 0 ? void 0 : classes.td, 
            // Alignment.
            {
                'text-start': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'left',
                'text-center': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'center',
                'text-end': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'right'
            }, typeof cellProps.className === 'function'
                ? cellProps.className(rowData)
                : cellProps.className), style: typeof cellProps.style === 'function'
                ? cellProps.style(rowData)
                : cellProps.style }, value));
    }
    let passedRowProps = {};
    if (typeof rowProps === 'function') {
        passedRowProps = rowProps(rowData);
    }
    else if (rowProps !== undefined) {
        passedRowProps = rowProps;
    }
    return (React.createElement("tr", Object.assign({}, passedRowProps, { className: makeClasses('tbody-tr', classes === null || classes === void 0 ? void 0 : classes.tr, passedRowProps === null || passedRowProps === void 0 ? void 0 : passedRowProps.className), onClick: onRowClick }), row));
}
/**
 * `EmptyTablePlaceholder` is a component used to indicate that a table is empty, or doesn't
 * have matching search results.
 *
 * @param param0
 * @returns
 */
export function EmptyTablePlaceholder({ className, noResultsLabel }) {
    const { headers } = useDatatableWrapper();
    return (React.createElement("tr", { className: makeClasses('tbody-tr', className) },
        React.createElement("td", { className: "tbody-td", colSpan: headers.length }, noResultsLabel || 'No results to be shown.')));
}
