//http://www.rudilion.com/wp-content/uploads/2013/04/rudilion_goals.pdf
export default [

    {
        "id": "treatment-goals-1",
        "title": "With the help of therapy, I'd like to tackle…",
        "options": [
            "Depressive symptoms",
            "Suicidality and self-injury",
            "Fears or anxiety",
            "Obsessive thoughts / compulsive behaviours",
            "Coping with trauma",
            "Substance abuse and addiction",
            "Eating behaviours",
            "Sleep",
            "Sexuality",
            "Coping with somatic probs (pain, chronic illness, meds)",
            "Difficulties in specific life domains (stress)"
        ],
        "type": "checkboxes",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "treatment-goals-2",
        "title": "With the help of therapy, I'd like to work on… ",
        "options": [
            "Current (romantic) relationship",
            "Parenthood",
            "Other specific relationships (friendships, other family)",
            "Loneliness and grief",
            "Assertiveness",
            "Connectedness and intimacy"
        ],
        "type": "checkboxes",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "treatment-goals-3",
        "title": "With the help of therapy, I'd like to improve…",
        "options": [
            "Exercise and activity",
            "Relaxation and composure",
            "Well-being",
            "Home and work (income) fulfillment and functioning"
        ],
        "type": "checkboxes",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "treatment-goals-4",
        "title": "With the help of therapy, I'd like to work on…",
        "options": [
            "Past, present, and future (e.g., personal history)",
            "Meaning of life (e.g., religious, spiritual)"
        ],
        "type": "checkboxes",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "treatment-goals-5",
        "title": "With the help of therapy, I'd like to improve…",
        "options": [
            "Attitude towards self",
            "Desires and wishes",
            "Responsibility and self-control",
            "Emotion regulation"
        ],
        "type": "checkboxes",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "treatment-goals-custom",
        "title": "Can you think of other goals for therapy that didn't fit with the prior options?",
        "options": [
           
        ],
        "type": "text",
        "format": "",
        "values": [],
        "conditions": []
    }
];