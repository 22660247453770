export default [
    {
        "id": "gad-1",
        "title": "Over the last two weeks, how often have you been bothered by feeling nervous, anxious, or on the edge",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-2",
        "title": "How often have you not been able to stop or control worrying",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-3",
        "title": "How often have you been worrying too much about different things",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-4",
        "title": "Over the last two weeks, how often have you had trouble relaxing",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-5",
        "title": "How often have you been so restless that it is hard to sit still",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-6",
        "title": "How often have you become easily annoyed or irritable",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-7",
        "title": "Over the last two weeks how often have you felt afraid, as if something awful might happen",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "gad-8",
        "title": "If above you have checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?",
        "options": [
            "Not difficult at all",
            "Somewhat difficult",
            "Very difficult",
            "Extremely difficult"
        ],
        "type": "Likert",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    }
];