import * as React from 'react'

export default function AbsBG({url, children, opacity=0.3, borderRadius="0"} : {url?: string, opacity?:number, children: React.ReactNode, borderRadius?: string}) {
    return (
        <>
        <div className="d-none  d-lg-block orfeo-bg" style={{ 
            width: "100%", 
            bottom:0,
             backgroundSize: "cover", borderRadius, backgroundPosition:"center -50px", backgroundImage: url ? `url(${url})`: undefined, position: "absolute", top: 0, left: 0, zIndex: 0, opacity,

            "filter": "blur(4px)",
             }} />
        <div className="d-xs-block d-sm-block d-lg-none orfeo-bg" style={{ width: "100%", bottom:0, backgroundSize: "cover", borderRadius, 

"filter": "blur(4px)",
        backgroundPosition:"center center", backgroundImage: url ? `url(${url})`: undefined, position: "absolute", top: 0, left: 0, zIndex: 0, opacity }} />
        <div  style={{ position: "relative", zIndex: 2, borderRadius: 0 }}>{children}
        </div>
        </>
    )
}
