export default  [
    {
        "id": "young-2",
        "title": "People have not been there to meet my emotional needs.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-3",
        "title": "I haven't gotten enough love and attention.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-4",
        "title": "For the most part, I haven't had someone to depend on for advice and emotional support.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-5",
        "title": "Most of the time, I haven't had someone to nurture me, share him/herself with me, or care deeply about everything that happens to me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-6",
        "title": "For much of my life, I haven't had someone who wanted to get close to me and spend a lot of time with me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-7",
        "title": "In general, people have not been there to give me warmth, holding, and affection.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-8",
        "title": "For much of my life, I haven't felt that I am special to someone.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-9",
        "title": "For the most part, I have not had someone who really listens to me, understands me, or is tuned into my true needs and feelings.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-10",
        "title": "I have rarely had a strong person to give me sound advice or direction when I'm not sure what to do. *ed",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-12",
        "title": "I worry that the people I love will die soon, even though there is little medical reason to support my concern.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-13",
        "title": "I find myself clinging to people I'm close to, because I'm afraid they'll leave me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-14",
        "title": "I worry that people I feel close to will leave me or abandon me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-15",
        "title": "I feel that I lack a stable base of emotional support.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-16",
        "title": "I don't feel that important relationships will last; I expect them to end.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-17",
        "title": "I feel addicted to partners who can't be there for me in a committed way.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-18",
        "title": "In the end, I will be alone.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-19",
        "title": "When I feel someone I care for pulling away from me, I get desperate.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-20",
        "title": "Sometimes I am so worried about people leaving me that I drive them away.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-21",
        "title": "I become upset when someone leaves me alone, even for a short period of time.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-22",
        "title": "I can't count on people who support me to be there on a regular basis.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-23",
        "title": "I can't let myself get really close to other people, because I can't be sure they'll always be there.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-24",
        "title": "It seems that the important people in my life are always coming and going.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-25",
        "title": "I worry a lot that the people I love will find someone else they prefer and leave me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-26",
        "title": "The people close to me have been very unpredictable: one moment they're available and nice to me; the next, they're angry, upset, self-absorbed, fighting, etc.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-27",
        "title": "I need other people so much that I worry about losing them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-28",
        "title": "I can't be myself or express what I really feel, or people will leave me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-30",
        "title": "I feel that people will take advantage of me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-31",
        "title": "I often feel that I have to protect myself from other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-32",
        "title": "I feel that I cannot let my guard down in the presence of other people, or else they will intentionally hurt me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-33",
        "title": "If someone acts nicely towards me, I assume that he/she must be after something.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-34",
        "title": "It is only a matter of time before someone betrays me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-35",
        "title": "Most people only think about themselves.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-36",
        "title": "I have a great deal of difficulty trusting people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-37",
        "title": "I am quite suspicious of other people's motives.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-38",
        "title": "Other people are rarely honest; they are usually not what they appear.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-39",
        "title": "I'm usually on the lookout for people's ulterior motives.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-40",
        "title": "If I think someone is out to hurt me, I try to hurt him or her first.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-41",
        "title": "People usually have to prove themselves to me before I can trust them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-42",
        "title": "I set up \"tests\" for other people, to see if they are telling me the truth and are wellintentioned.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-43",
        "title": "I subscribe to the belief: \"Control or be controlled.\"",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-44",
        "title": "I get angry when I think about the ways I have been mistreated by other people during my life.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-45",
        "title": "During my life, those close to me have taken advantage of me or used me for their own purposes.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-46",
        "title": "I have been physically, emotionally, or sexually abused by important people in my life. *ma",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-48",
        "title": "I don't fit in.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-49",
        "title": "I'm fundamentally different from other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-50",
        "title": "I don't belong; I'm a loner.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-51",
        "title": "I feel alienated from other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-52",
        "title": "I feel isolated and alone.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-53",
        "title": "I always feel on the outside of groups.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-54",
        "title": "No one really understands me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-55",
        "title": "My family was always different from the families around us.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-56",
        "title": "I sometimes feel as if I'm an alien.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-57",
        "title": "If I disappeared tomorrow, no one would notice.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-59",
        "title": "No man/woman I desire could love me once he/she saw my defects.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-60",
        "title": "No one I desire would want to stay close to me if he/she knew the real me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-61",
        "title": "I am inherently flawed and defective.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-62",
        "title": "No matter how hard I try, I feel that I won't be able to get a significant man/woman to respect me or feel that I am worthwhile.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-63",
        "title": "I'm unworthy of the love, attention, and respect of others.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-64",
        "title": "I feel that I'm not lovable.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-65",
        "title": "I am too unacceptable in very basic ways to reveal myself to other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-66",
        "title": "If others found out about my basic defects, I could not face them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-67",
        "title": "When people like me, I feel I am fooling them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-68",
        "title": "I often find myself drawn to people who are very critical of me or reject me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-69",
        "title": "I have inner secrets that I don't want people close to me to find out.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-70",
        "title": "It is my fault that my parent(s) could not love me enough.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-71",
        "title": "I don't let people know the real me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-72",
        "title": "One of my greatest fears is that my defects will be exposed.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-73",
        "title": "I cannot understand how anyone could love me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },

    {
        "id": "young-75",
        "title": "Almost nothing I do at work (or school) is as good as other people can do.",
        "options": [
            "Completely untrue of me"
        ],
        "type": "Likert",
        "values": [
            1
        ],
        "conditions": []
    },
    {
        "id": "young-76",
        "title": "Almost nothing I do at work (or school) is as good as other people can do.",
        "options": [
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-77",
        "title": "I'm incompetent when it comes to achievement.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-78",
        "title": "Most other people are more capable than I am in areas of work (or school) and achievement.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-79",
        "title": "I'm a failure.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-80",
        "title": "I'm not as talented as most people are at their work (or at school).",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-81",
        "title": "I'm not as intelligent as most people when it comes to work (or school).",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-82",
        "title": "I am humiliated by my failures and inadequacies in the work (or school) sphere.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-83",
        "title": "I often feel embarrassed around other people, because I don't measure up to them in terms of my accomplishments.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-84",
        "title": "I often compare my accomplishments with others and feel that they are much more successful.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-86",
        "title": "I do not feel capable of getting by on my own in everyday life.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-87",
        "title": "I need other people to help me get by.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-88",
        "title": "I do not feel I can cope well by myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-89",
        "title": "I believe that other people can take of me better than I can take care of myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-90",
        "title": "I have trouble tackling new tasks outside of work, unless I have someone to guide me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-91",
        "title": "I think of myself as a dependent person when it comes to everyday functioning.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-92",
        "title": "I screw up everything I try, even outside of work (or school).",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-93",
        "title": "I'm inept in most areas of life.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-94",
        "title": "If I trust my own judgment in everyday situations, I'll make the wrong decision.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-95",
        "title": "I lack common sense.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-96",
        "title": "My judgment cannot be relied on in everyday situations.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-97",
        "title": "I don't feel confident about my ability to solve everyday problems that come up.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-98",
        "title": "I feel that I need someone I can rely on to give me advice about practical issues.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-99",
        "title": "I feel more like a child than an adult when it comes to handling everyday responsibilities.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-100",
        "title": "I find the responsibilities of everyday life overwhelming.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-102",
        "title": "I can't seem to escape the feeling that something bad is about to happen.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-103",
        "title": "I feel that a disaster (natural, criminal, financial, or medical) could strike at any moment.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-104",
        "title": "I worry about becoming a street person or vagrant.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-105",
        "title": "I worry about being attacked.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-106",
        "title": "I take great precautions to avoid getting sick or hurt.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-107",
        "title": "I worry that I'm developing a serious illness, even though nothing serious has been diagnosed by a physician.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-108",
        "title": "I am a fearful person.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-109",
        "title": "I worry a lot about the bad things happening in the world: crime, pollution, etc.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-110",
        "title": "I often feel that I might go crazy.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-111",
        "title": "I often feel that I'm going to have an anxiety attack.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-112",
        "title": "I often worry that I might have a heart attack or cancer, even though there is little medical reason to be concerned.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-113",
        "title": "I feel that the world is a dangerous place.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-115",
        "title": "I have not been able to separate myself from my parent(s), the way other people my age seem to.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-116",
        "title": "My parent(s) and I tend to be overinvolved in each other's lives and problems.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-117",
        "title": "It is very difficult for my parent(s) and me to keep intimate details from each other, without feeling betrayed or guilty.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-118",
        "title": "My parent(s) and I have to speak to each other almost every day, or else one of us feels guilty, hurt, disappointed, or alone.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-119",
        "title": "I often feel that I do not have a separate identity from my parents or partner.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-120",
        "title": "I often feel as if my parent(s) are living through me -- I don't have a life of my own.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-121",
        "title": "It is very difficult for me to maintain any distance from the people I am intimate with; I have trouble keeping any separate sense of myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-122",
        "title": "I am so involved with my partner or parent(s) that I do not really know who I am or what I want.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-123",
        "title": "I have trouble separating my point of view or opinion from that of my parent(s) or partner.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-124",
        "title": "I often feel that I have no privacy when it comes to my parent(s) or partner.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-125",
        "title": "I feel that my parent(s) are, or would be, very hurt about my living on my own, away from them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-127",
        "title": "I let other people have their way, because I fear the consequences.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-128",
        "title": "I believe that if I do what I want, I'm only asking for trouble.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-129",
        "title": "I feel that I have no choice but to give in to other people’s wishes, or else they will retaliate or reject me in some way.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-130",
        "title": "In relationships, I let the other person have the upper hand.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-131",
        "title": "I've always let others make choices for me, so I really don't know what I want for myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-132",
        "title": "I feel the major decisions in my life were not really my own.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-133",
        "title": "I worry a lot about pleasing other people, so they won't reject me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-134",
        "title": "I have a lot of trouble demanding that my rights be respected and that my feelings be taken into account.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-135",
        "title": "I get back at people in little ways instead of showing my anger directly.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-136",
        "title": "I will go to much greater lengths than most people to avoid confrontations.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-138",
        "title": "I put others' needs before my own, or else I feel guilty.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-139",
        "title": "I feel guilty when I let other people down or disappoint them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-140",
        "title": "I give more to other people than I get back in return.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-141",
        "title": "I'm the one who usually ends up taking care of the people I'm close to.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-142",
        "title": "There is almost nothing I couldn't put up with if I loved someone.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-143",
        "title": "I am a good person because I think of others more than of myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-144",
        "title": "At work, I'm usually the one to volunteer to do extra tasks or to put in extra time.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-145",
        "title": "No matter how busy I am, I can always find time for others.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-146",
        "title": "I can get by on very little, because my needs are minimal.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-147",
        "title": "I'm only happy when those around me are happy.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-148",
        "title": "I'm so busy doing for the people that I care about that I have little time for myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-149",
        "title": "I've always been the one who listens to everyone else's problems.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly",
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6,
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-150",
        "title": "Other people see me as doing too much for others and not enough for myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-151",
        "title": "No matter how much I give, I feel it is never enough.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-152",
        "title": "If I do what I want, I feel very uncomfortable.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-153",
        "title": "It's very difficult for me to ask others to take care of my needs.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-155",
        "title": "I worry about losing control of my actions.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-156",
        "title": "I worry that I might seriously harm someone physically or emotionally, if my anger gets out of control.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-157",
        "title": "I feel that I must control my emotions and impulses, or something bad is likely to happen.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-158",
        "title": "A lot of anger and resentment build up inside of me that I don't express.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-159",
        "title": "I am too self-conscious to show positive feelings to others (e.g., affection, showing I care).",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-160",
        "title": "I find it embarrassing to express my feelings to others.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-161",
        "title": "I find it hard to be warm and spontaneous.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-162",
        "title": "I control myself so much that people think I am unemotional.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-163",
        "title": "People see me as uptight emotionally.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-165",
        "title": "I must be the best at most of what I do; I can't accept second best.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-166",
        "title": "I strive to keep almost everything in perfect order.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-167",
        "title": "I must look my best most of the time.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-168",
        "title": "I try to do my best; I can't settle for \"good enough.\"",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-169",
        "title": "I have so much to accomplish that there is almost no time to really relax.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-170",
        "title": "Almost nothing I do is quite good enough; I can always do better.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-171",
        "title": "I must meet all my responsibilities.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-172",
        "title": "I feel there is constant pressure for me to achieve and get things done.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-173",
        "title": "My relationships suffer because I push myself so hard.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-174",
        "title": "My health is suffering because I put myself under so much pressure to do well.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-175",
        "title": "I often sacrifice pleasure and happiness to meet my own standards.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-176",
        "title": "When I make a mistake, I deserve strong criticism.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-177",
        "title": "I can't let myself off the hook easily or make excuses for my mistakes.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-178",
        "title": "I'm a very competitive person.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-179",
        "title": "I put a good deal of emphasis on money or status.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-180",
        "title": "I always have to be Number One, in terms of my performance.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-182",
        "title": "I have a lot of trouble accepting \"no\" for an answer when I want something from other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-183",
        "title": "I often get angry or irritable if I can't get what I want.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-184",
        "title": "I'm special and shouldn't have to accept many of the restrictions placed on other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-185",
        "title": "I hate to be constrained or kept from doing what I want.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly",
            "Slightly more true than untrue"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6,
            3
        ],
        "conditions": []
    },
    {
        "id": "young-186",
        "title": "I feel that I shouldn't have to follow the normal rules and conventions other people do.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-187",
        "title": "I feel that what I have to offer is of greater value than the contributions of others.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-188",
        "title": "I usually put my needs ahead of the needs of others.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-189",
        "title": "I often find that I am so involved in my own priorities that I don't have time to give to friends or family.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-190",
        "title": "People often tell me I am very controlling about the ways things are done.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-191",
        "title": "I get very irritated when people won't do what I ask of them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-192",
        "title": "I can't tolerate other people telling me what to do.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-194",
        "title": "I have great difficulty getting myself to stop drinking, smoking, overeating, or other problem behaviors.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-195",
        "title": "I can't seem to discipline myself to complete routine or boring tasks.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-196",
        "title": "Often I allow myself to carry through on impulses and express emotions that get me into trouble or hurt other people.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-197",
        "title": "If I can't reach a goal, I become easily frustrated and give up.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-198",
        "title": "I have a very difficult time sacrificing immediate gratification to achieve a long-range goal.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-199",
        "title": "It often happens that, once I start to feel angry, I just can't control it.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-200",
        "title": "I tend to overdo things, even though I know they are bad for me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-201",
        "title": "I get bored very easily.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-202",
        "title": "When tasks become difficult, I usually cannot persevere and complete them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-203",
        "title": "I can't concentrate on anything for too long.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-204",
        "title": "I can't force myself to do things I don't enjoy, even when I know it's for my own good.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-205",
        "title": "I lose my temper at the slightest offense.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-206",
        "title": "I have rarely been able to stick to my resolutions.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-207",
        "title": "I can almost never hold back from showing people how I really feel, no matter what the cost may be.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-208",
        "title": "I often do things impulsively that I later regret.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-210",
        "title": "It is important to me to be liked by almost everyone I know.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-211",
        "title": "I change myself depending on the people I’m with, so they’ll like me more.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-212",
        "title": "I try hard to fit in.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-213",
        "title": "My self-esteem is based mostly on how other people view me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-214",
        "title": "Having money and knowing important people make me feel worthwhile.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-215",
        "title": "I spend a lot of time on my physical appearance so people will value me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-216",
        "title": "Accomplishments are most valuable to me if other people notice them.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-217",
        "title": "I am so focused on fitting in that sometimes I don’t know who I am.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-218",
        "title": "I find it hard to set my own goals, without taking into account how others will respond to my choices.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-219",
        "title": "When I look at my life decisions, I see that I made most of them with other people’s approval in mind.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-220",
        "title": "Even if I don’t like someone, I still want him or her to like me.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-221",
        "title": "Unless I get a lot of attention from others, I feel less important.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-222",
        "title": "If I make remarks at a meeting or am introduced at a gathering, I look forward to recognition and admiration.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-223",
        "title": "Lots of praise and compliments make me feel like a worthwhile person.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-225",
        "title": "Even when things seem to be going well, I feel that it is only temporary.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-226",
        "title": "If something good happens, I worry that something bad is likely to follow.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-227",
        "title": "You can’t be too careful; something will almost always go wrong.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-228",
        "title": "No matter how hard I work, I worry that I could be wiped out financially.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-229",
        "title": "I worry that a wrong decision could lead to disaster.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-230",
        "title": "I often obsess over minor decisions, because the consequences of making a mistake seem so serious.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-231",
        "title": "I feel better assuming that things will not work out for me, so that I don’t feel disappointed if things go wrong.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-232",
        "title": "I focus more on the negative aspects of life and of events than on the positive.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-233",
        "title": "I tend to be pessimistic.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-234",
        "title": "People close to me consider me a worrier.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-235",
        "title": "If people get too enthusiastic about something, I become uncomfortable and feel like warning them of what could go wrong.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },

    {
        "id": "young-237",
        "title": "If I make a mistake, I deserve to be punished.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-238",
        "title": "If I don’t try my hardest, I should expect to lose out.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-239",
        "title": "There is no excuse if I make mistake.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-240",
        "title": "People who don’t “pull their own weight” should get punished in some way.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-241",
        "title": "Most of the time, I don’t accept the excuses other people make. They’re just not willing to accept responsibility and pay the consequences.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-242",
        "title": "If I don’t do the job, I should suffer the consequences.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-243",
        "title": "I often think about mistakes I’ve made and feel angry with myself.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-244",
        "title": "When people do something bad, I have trouble applying the phrase, “Forgive and forget”.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-245",
        "title": "I hold grudges, even after someone has apologized.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-246",
        "title": "I get upset when I think someone has been “let off the hook” too easily.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-247",
        "title": "I get angry when people make excuses for themselves, or blame other people for their problems.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-248",
        "title": "It doesn’t matter why I make a mistake; when I do something wrong, I should pay the price.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-249",
        "title": "I “beat up” on myself a lot for things I screw up.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "young-250",
        "title": "I’m a bad person who deserves to be punished.",
        "options": [
            "Completely untrue of me",
            "Mostly untrue of me",
            "Slightly more true than untrue",
            "Moderately true of me",
            "Mostly true of me",
            "Describes me perfectly"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    }
];