import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { filterData, getNextSortState, paginateData, sortData } from '../helpers/data';
import { convertArrayToRecord } from '../helpers/object';
import { createCtx } from '../helpers/react';
const [useCtx, Provider] = createCtx();
/**
 * @internal
 *
 * This is an internal hook to use in all table components.
 */
export const useDatatableWrapper = useCtx;
export function DatatableWrapper({ headers, body, isControlled: isControlledProp, filterProps, sortProps, paginationProps, paginationOptionsProps, checkboxProps, tableEventsRef, children }) {
    const [state, setState] = useState(getDefaultDatatableState({
        filterProps,
        headers,
        sortProps,
        paginationProps,
        paginationOptionsProps,
        checkboxProps
    }));
    const [isControlled, setIsControlled] = useState(isControlledProp || false);
    const headersRecordRef = useRef(convertArrayToRecord(headers, 'prop'));
    // Store this in a ref because we might need it in case `headers` change.
    // Though, we don't want to put these into `useEffect` because they most likely
    // change over time.
    const controlPropsRef = useRef({
        filterProps,
        sortProps,
        paginationProps,
        paginationOptionsProps,
        checkboxProps
    });
    const checkboxRefs = useTableHeaderCheckbox({
        headers,
        initialStates: checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.initialState
    });
    useEffect(() => {
        if (tableEventsRef !== undefined) {
            console.warn('Warning: Usage of `tableEventsRef` is deprecated. Consider using `useDatatableWrapper`' +
                'and raising the `DatatableWrapper` a bit higher in the structure instead.');
        }
    }, [tableEventsRef]);
    useEffect(() => {
        // Resets the table if the headers passed down is different.
        if (headers !== undefined && !isControlled) {
            // Re-set the ref.
            headersRecordRef.current = convertArrayToRecord(headers, 'prop');
            // Re-set the initial state.
            setState(getDefaultDatatableState({
                filterProps: controlPropsRef.current.filterProps,
                headers,
                sortProps: controlPropsRef.current.sortProps,
                paginationProps: controlPropsRef.current.paginationProps,
                paginationOptionsProps: controlPropsRef.current.paginationOptionsProps,
                checkboxProps: controlPropsRef.current.checkboxProps
            }));
        }
    }, [headers, isControlled]);
    useEffect(() => {
        // Resets the table if the data passed down is different.
        if (body !== undefined && !isControlled) {
            setState((oldState) => (Object.assign(Object.assign({}, oldState), { filter: '', pagination: Object.assign(Object.assign({}, oldState.pagination), { currentPage: 1 }) })));
        }
    }, [body, isControlled]);
    const onFilterChange = useCallback((text) => {
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { filter: text, pagination: Object.assign(Object.assign({}, oldState.pagination), { currentPage: 1 }) })));
    }, []);
    const onPaginationChange = useCallback((nextPage) => {
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { pagination: Object.assign(Object.assign({}, oldState.pagination), { currentPage: nextPage }) })));
    }, []);
    const onRowsPerPageChange = useCallback((numOfPage) => {
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { pagination: Object.assign(Object.assign({}, oldState.pagination), { rowsPerPage: numOfPage, currentPage: 1 }) })));
    }, []);
    const onSortChange = useCallback((nextSort) => {
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { sort: nextSort })));
    }, []);
    const onSortByPropChange = useCallback((sortedProp) => {
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { sort: getNextSortState(oldState.sort, sortedProp) })));
    }, []);
    const onCheckboxChange = useCallback(({ prop, nextCheckboxState, checkboxRefs, idProp }) => {
        // We put this here because it'll be easier to switch between
        // controlled and uncontrolled this way.
        checkboxRefs.current[prop].indeterminate =
            nextCheckboxState.state === 'some-selected';
        setState((oldState) => (Object.assign(Object.assign({}, oldState), { checkbox: Object.assign(Object.assign({}, oldState.checkbox), { [prop]: nextCheckboxState }), previouslyModifiedCheckbox: {
                idProp,
                prop
            } })));
    }, []);
    // Imperative handle.
    // This is if we want to keep the table events controllable from outside,
    // without making the table controlled.
    // TODO(imballinst): rethink about this for the next major version (4.x).
    // https://github.com/imballinst/react-bs-datatable/pull/123#issuecomment-1050582200.
    useImperativeHandle(tableEventsRef, () => ({
        onFilterChange,
        onPaginationChange,
        onRowsPerPageChange,
        onSortByPropChange,
        onCheckboxChange
    }), []);
    const { filter, sort, pagination, isFilterable } = state;
    const { data, filteredDataLength, maxPage } = useMemo(() => {
        let newData = body;
        let newFilteredDataLength = newData.length;
        let newMaxPage = 1;
        if (!isControlled) {
            // Only do this in uncontrolled mode.
            if (isFilterable) {
                newData = filterData(body, headersRecordRef.current, filter);
                newFilteredDataLength = newData.length;
            }
            newData = sortData(newData, sort, sortProps === null || sortProps === void 0 ? void 0 : sortProps.sortValueObj);
            if (pagination.rowsPerPage !== -1) {
                // Pagination needs.
                newMaxPage = Math.ceil(newData.length / pagination.rowsPerPage);
                newData = paginateData(newData, pagination.currentPage, pagination.rowsPerPage);
            }
        }
        return {
            data: newData,
            maxPage: newMaxPage,
            filteredDataLength: newFilteredDataLength
        };
    }, [isControlled, body, filter, sort, pagination, isFilterable]);
    return (React.createElement(Provider, { value: {
            headers,
            setIsControlled,
            // Filter.
            isFilterable: isFilterable,
            filterState: filter,
            onFilterChange,
            // Sort.
            sortState: sort,
            onSortChange,
            onSortByPropChange,
            // Pagination.
            currentPageState: pagination.currentPage,
            onPaginationChange,
            // Pagination options.
            rowsPerPageState: pagination.rowsPerPage,
            rowsPerPageOptions: pagination.rowsPerPageOptions,
            onRowsPerPageChange,
            // Checkbox.
            checkboxState: state.checkbox,
            previouslyModifiedCheckbox: state.previouslyModifiedCheckbox,
            onCheckboxChange,
            checkboxRefs,
            // Data.
            maxPage,
            filteredDataLength,
            data,
            body
        } }, children));
}
// Helper functions.
function getDefaultDatatableState({ filterProps, paginationOptionsProps, paginationProps, sortProps, checkboxProps, headers }) {
    var _a, _b, _c, _d;
    const defaultSort = (sortProps === null || sortProps === void 0 ? void 0 : sortProps.initialState) || {
        order: 'asc',
        prop: ''
    };
    const checkbox = (checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.initialState) || {};
    let isFilterable = filterProps !== undefined;
    for (const header of headers) {
        const prop = header.prop.toString();
        if (defaultSort.prop === '' && header.isSortable) {
            // If the sorted prop is still "empty", then we assign it with the current header.
            defaultSort.prop = String(header.prop);
        }
        // Set the default checkbox values, if not provided from `checkboxProps`.
        if (header.checkbox && (checkboxProps === null || checkboxProps === void 0 ? void 0 : checkboxProps.initialState) === undefined) {
            checkbox[prop] = { state: 'none-selected', selected: new Set() };
        }
        if (header.isFilterable) {
            isFilterable = true;
        }
    }
    // Define initial state.
    return {
        isFilterable,
        filter: ((_a = filterProps === null || filterProps === void 0 ? void 0 : filterProps.initialState) === null || _a === void 0 ? void 0 : _a.filter) || '',
        sort: defaultSort,
        pagination: {
            currentPage: ((_b = paginationProps === null || paginationProps === void 0 ? void 0 : paginationProps.initialState) === null || _b === void 0 ? void 0 : _b.page) || 1,
            rowsPerPage: ((_c = paginationOptionsProps === null || paginationOptionsProps === void 0 ? void 0 : paginationOptionsProps.initialState) === null || _c === void 0 ? void 0 : _c.rowsPerPage) || -1,
            rowsPerPageOptions: ((_d = paginationOptionsProps === null || paginationOptionsProps === void 0 ? void 0 : paginationOptionsProps.initialState) === null || _d === void 0 ? void 0 : _d.options) || []
        },
        checkbox,
        previouslyModifiedCheckbox: {
            idProp: '',
            prop: ''
        }
    };
}
function useTableHeaderCheckbox({ headers, initialStates }) {
    const checkboxRefs = useRef({});
    useEffect(() => {
        if (initialStates) {
            for (const header of headers) {
                const prop = header.prop.toString();
                const checkbox = checkboxRefs.current[prop];
                if (checkbox !== null) {
                    checkbox.indeterminate =
                        initialStates[prop].state === 'some-selected';
                }
            }
        }
    }, [headers, initialStates]);
    return checkboxRefs;
}
