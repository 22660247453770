export default [
    {
        "id": "ace-1",
        "title": "Did a parent or other adult in the household often or very often… Swear at you, insult you, put you down, or humiliate you? or Act in a way that made you afraid that you might be physically hurt?",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-2",
        "title": "Did a parent or other adult in the household often or very often… Push, grab, slap, or throw something at you? or Ever hit you so hard that you had marks or were injured? ",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-3",
        "title": "Did an adult or person at least 5 years older than you ever… Touch or fondle you or have you touch their body in a sexual way? or Attempt or actually have oral, anal, or vaginal intercourse with you? ",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-4",
        "title": "Did you often or very often feel that … No one in your family loved you or thought you were important or special? or Your family didn’t look out for each other, feel close to each other, or support each other?",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-5",
        "title": "Did you often or very often feel that … You didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you? or Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-6",
        "title": "Were your parents ever separated or divorced? ",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-7",
        "title": "Was your mother or stepmother: Often or very often pushed, grabbed, slapped, or had something thrown at her? or Sometimes, often, or very often kicked, bitten, hit with a fist, or hit with something hard? or Ever repeatedly hit at least a few minutes or threatened with a gun or knife? ",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-8",
        "title": "Did you live with anyone who was a problem drinker or alcoholic or who used street drugs?",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-9",
        "title": "Was a household member depressed or mentally ill, or did a household member attempt suicide? ",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    },
    {
        "id": "ace-10",
        "title": "Did a household member go to prison?",
        "options": [
            "Yes",
            "No"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            1,
            0
        ],
        "conditions": []
    }
];