import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../config';
import SessionListItem from './SessionListItem';
import { Session, SessionListProps } from './types';
import { Card, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';


const headers = [
    { title: 'Clinic', prop: 'clinic_name' },
    { title: 'Scheduled At', prop: 'scheduled_at' },
    { title: 'Status', prop: 'status' }
];

export default function SessionCard(props: SessionListProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [sessions, setSessions] = useState<Session[]>([]);

    // Fetch from /sessions endpoint 
    useEffect(() => {
        setIsLoading(true);
        fetch(`${BASE_URL}/sessions?_start=0&_end=10&_sort=scheduled_at&_order=ASC`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Sessions:', data);
                if (data && data.length > 0)
                    setSessions(data);
            }
            )
            .catch((error) => {
                console.error('Error loading sessions:', error);
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])


    return (
        <Card className={props.className || ''} text="dark">
            <Card.Header as="h5">Upcoming Sessions</Card.Header>
            <Card.Body>
                {isLoading ? <div>Loading...</div> : <div>
                    <DatatableWrapper  body={sessions.map((session: Session) => { return {...session, scheduled_at: moment.tz(session.scheduled_at, session.scheduled_at_tz).format('MM/DD/YYYY hh:mm A z')
                    }} )} headers={headers}>
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                            >
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                            </Col>
                        </Row>
                        <Table>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </DatatableWrapper>
                </div>
                }
            </Card.Body>
        </Card>
    );
}

