import * as React from 'react';
import { useWhisper } from '../hoc/useWhisper'
import get from 'lodash.get';
import { Button, ButtonGroup, Card, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { MDBBadge } from 'mdb-react-ui-kit';
import axios from 'axios';
import { UseWhisperTranscript } from '@chengsokdara/use-whisper/dist/types';


const apiKey = "";
let cancelToken;
let interval: NodeJS.Timer | undefined = undefined;


type SessionExtract = {
    type: 'family' | 'event' | 'fact' | 'feeling' | 'person' | 'challenge' | 'belief' | 'other' |  'treatment' | 'outcome' | 'summary',
    text: string,
    relation?: string,
    outcome?: string
};
const returnType = `type SessionExtracts = {
    type: 'family' | 'event' | 'fact' | 'feeling' | 'person' | 'challenge' | 'belief' | 'other' | 'treatment' | 'outcome' | 'summary',
    text: string,
    relation?: string,
    outcome?: string
  };`

const createPrompt = (transcript: string): { "role": 'user' | 'system', "content": string }[] => {
    return [
        { 'role': 'system', 'content': `You are a tool organize therapist notes. You will be provided you with a transcript of a therapy session and you will be asked to process it in a structured format. You will only multiple outputs in the structured format json. The structured format is as follows: \`${returnType}\`` },
        {
            'role': 'user', 'content': `Summarize this converation, therapy session: ${transcript} and organize it in a structured format. Show only the JSON output. For example: 
            Me>> Alright, so I cheat, I used to cheat. I'm not getting spayed. End of subject. You brought up the vasectomy. Now you don't want to talk about it. You asked me what was going on. I saw on the news about a gangland shooting. Yeah. Is that your nephew Christopher that you've spoken of before? In the papers they said he was in critical condition. I'll be fine. Have they found the person that shot him yet? No. Have you? I'm not trying to pry into that part of your life. I know our deal. But I've heard you say before that you love this young man. Christopher was clinically dead for about a minute. He thinks he had one of those near death experiences. Says he visited hell and they told him he'd be back permanent. Who's they? It's bullshit. It was a dream combined with the morphine. But now he thinks he's going to go to hell when he dies. He's all fucked up over it. Do you think he'll go to hell? No.
            You>>[
                { "type": "challenge", "text": "Client admits to past cheating and refuses vasectomy.", "relation": "unapologetic", "section": "Alright, so I cheat, I used to cheat. I'm not getting spayed. End of subject. You brought up the vasectomy. Now you don't want to talk about it." },
                { "type": "other", "text": "Client's nephew Christopher was in a gangland shooting and was clinically dead for about a minute, but survived.", "outcome": "survived" },
                { "type": "belief", "text": "Christopher believes he had a near-death experience and now thinks he'll go to hell when he dies." },
                { "type": "person", "text": "Christopher", "relationship" : "nephew", "relation": "love" },
                { "type": "feeling", "text": "Christopher is 'all f***ed up' over his belief." },
                { "type": "feeling", "text": "Worried about christopher.", "relation" : "Christopher" },
                { "tyoe": "summary", "text": "In this session the client talks about their cheating and refusal to get a vasectomy. They also touch on their relationship with the nephew and worry about his changing."}
            ]
        `}
    ]
}

async function makePostRequest(text, onResult: (data) => void) {
    // if (interval) {
    //     clearInterval(interval)
    // } else {
    //     console.log("starting")
    //     interval = setInterval(async () => {
    //         if (cancelToken) {
    //             cancelToken.cancel('Request cancelled due to new request.');
    //         }

    //         cancelToken = axios.CancelToken.source();
    try {
        const headers = {}
        headers['Content-Type'] = 'application/json'
        if (apiKey) {
            headers['Authorization'] = `Bearer ${apiKey}`
        }
        const response = await axios.post("https://api.openai.com/v1/chat/completions", `${JSON.stringify(
            {
                "model": "gpt-3.5-turbo",
                "messages": createPrompt(text || ""),
                // "max_tokens": 7,

            }
        )}`, {
            headers,
        })
        const data = await response.data
        onResult(data)
        console.log('POST request successful:', response.data);
        return data
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request cancelled:', error.message);
        } else {
            console.error('POST request failed:', error);
        }
    }
    //         }, 500);
    //     }
}


const Session = () => {
    const [status, setStatus] = React.useState("idle")
    const [loading, isLoading] = React.useState(false)
    const [summary, setSummary] = React.useState<any[]>([])
    const {
        recording,
        speaking,
        transcribing,
        transcript,
        pauseRecording,
        startRecording,
        stopRecording,
        setDevice
    } = useWhisper({
        //autoStart: true,
        removeSilence: true,
        autoTranscribe: true,
        streaming: true,
        apiKey, // YOUR_OPEN_AI_TOKEN
        onDataAvailable: (blob) => {
            //console.log("blob", blob)
        },
        // onTranscribe: (transcript: UseWhisperTranscript) => {

        // }
    })

    const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);
    const [selectedDevice, setSelectedDevice] = React.useState<MediaDeviceInfo | undefined>(undefined);
    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            setDevices(devices)
        }, (err) => {
            console.log(err)
        })
    }, [])

    /**
     * you have more control like this
     * do whatever you want with the recorded speech
     * send it to your own custom server
     * and return the response back to useWhisper
     */
    // const onTranscribe = async (blob: Blob) => {
    //     const base64 = await new Promise<string | ArrayBuffer | null>(
    //         (resolve) => {
    //             const reader = new FileReader()
    //             reader.onloadend = () => resolve(reader.result)
    //             reader.readAsDataURL(blob)
    //         }
    //     )
    //     const body = JSON.stringify({ file: base64, model: 'whisper-1' })
    //     const headers = { 'Content-Type': 'application/json' }
    //     const { default: axios } = await import('axios')
    //     const response = await axios.post('/api/whisper', body, {
    //         headers,
    //     })
    //     const { text } = await response.data
    //     // you must return result from your server in Transcript format
    //     return {
    //         blob,
    //         text,
    //     }
    // }
    let interval: undefined | NodeJS.Timer = undefined;

    const sampleText = "You brought up the vasectomy. Now you don't want to talk about it. You asked me what was going on. I saw on the news about a gangland shooting. That your nephew Christopher that you've spoken of before. The papers, they said he was in critical condition. I'll be fine. Have they found the person that shot him yet? No. Have you? I'm not trying to pry into that part of your life. I know our deal. But I've heard you say before that you love this young man. Christopher was clinically dead for about a minute. He thinks he had one of those near-death experiences. Says he visited hell. And they told him he'd be back permanent. Who's they? It's bullshit. It was a dream. Combined with the morphine. But now he thinks he's going to go to hell."

  

    const onFinishSession = () => {
        isLoading(true)
        stopRecording()
        makePostRequest(transcript.text, (data) => {
            setSummary(data.choices)
            isLoading(false)
        })

    }
    return (
        <Container className="mt-2">
            <Row>
                <Col lg={6}>
                    <Card style={{ boxShadow: "none" }}>
                        <Card.Header>
                            <span>
                                <Form className="form-inline">
                                <MDBBadge color={recording ? "success" : "primary"} >Recording</MDBBadge>
                                <MDBBadge color={speaking ? "success" : "primary"} >Speaking</MDBBadge>
                                <MDBBadge color={transcribing ? "success" : "primary"} >Transcribing</MDBBadge>
                                 <Form.Control 
                                        as="select" 
                                        style={{display: "inline-block"}}
                                        size="sm"
                                        onChange={(event) => { 
                                        const device = devices
                                            .filter((v)=> v.kind == 'audioinput')
                                            .find((d) => d.deviceId === event.target.value)

                                        if (device) {
                                            navigator.mediaDevices.getUserMedia({ audio: { deviceId: device.deviceId } }).then((stream) => {
                                                setDevice(stream)
                                            });
                                        }
                          
                                    }}>
                                        <option>
                                            Select Device
                                        </option>
                                        {devices
                                            .filter((v)=> v.kind == 'audioinput')
                                            .map((d) => <option value={d.deviceId}>{d.label}</option>)}
                                </Form.Control>
                                </Form>
                            </span>
                            
                        </Card.Header>
                        <Card.Body>
                            {transcript.text}
                        </Card.Body>
                        <Card.Footer>
                            <ButtonGroup>
                                <Button onClick={() => startRecording().then(() => setStatus("started"))}>Start Session</Button>
                                <Button disabled={!recording} onClick={() => pauseRecording()}>Pause Session</Button>
                                <Button disabled={!recording} onClick={() => stopRecording().then(() => setStatus("stopped"))}>Stop Session</Button>
                                <Button disabled={!transcript.text} onClick={() => onFinishSession()}>
                                    {loading && <Spinner animation="border" size="sm" />}
                                    Process Session
                                </Button>
                            </ButtonGroup>
                            
                        </Card.Footer>
                    </Card>

                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            Extract {loading && <Spinner animation="border" size="sm" />}
                        </Card.Header>
                        <Card.Body>
                            <hr />
                            <Container>
                                {summary && summary.length > 0 && summary.map((c) => {
                                    
                                    try {
                                        const x = JSON.parse(c.message.content)

                                        return <Row>
                                            <Table variant='responsive'>
                                                <tbody>
                                                    {x.map((a: SessionExtract) => <tr>
                                                        <td><MDBBadge>{a.type}</MDBBadge></td>
                                                        <td>{a.text}</td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>

                                        </Row>
                                    } catch (e) {
                                        console.log("FUCK", e.message)
                                        return <></>
                                    }
                                })}
                            </Container>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>
    )
}
export default Session;