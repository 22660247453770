const ORS = [
    {
        "id": "ors-0",
        "title": "Outcome Rating Scale (ORS)",
        "options": [],
        "type": "Text",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "ors-1",
        "title": "Looking back over the past couple of days since your last remote session, help us understand how you have been feeling by rating how well you have been doing in the following areas of your life, where marks to the left represent low levels and marks to the right indicate high levels. If you are filling out this form for another person, please fill out according to how you think he or she is doing.",
        "options": [],
        "type": "Text",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "ors-2",
        "title": "Individually (Personal well-being)",
        "options": [
            "Poor",
            "Weak",
            "Neutral",
            "Good",
            "Very good"
        ],
        "type": "Likert",
        "format": "Numerical ",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "ors-3",
        "title": "Interpersonally (Family, close relationships)",
        "options": [
            "Poor",
            "Weak",
            "Neutral",
            "Good",
            "Very good"
        ],
        "type": "Likert",
        "format": "Numerical ",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "ors-4",
        "title": "Socially (Work, school, friendships)",
        "options": [
            "Poor",
            "Weak",
            "Neutral",
            "Good",
            "Very good"
        ],
        "type": "Likert",
        "format": "Numerical ",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "ors-5",
        "title": "Overall (General sense of well-being)",
        "options": [
            "Poor",
            "Weak",
            "Neutral",
            "Good",
            "Very good"
        ],
        "type": "Likert",
        "format": "Numerical ",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    }
]

export default ORS