import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { BASE_URL } from "../config";
import { Container, Row, Col, Table, Button, Spinner, Form, Card } from 'react-bootstrap';
//import { doc, getDoc } from 'firebase/firestore';
import { Path, useNavigate } from 'react-router-dom';
import AbsBG from '../components/helpers/AbsBg';
import { VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { UserData } from '../types';
import SessionCard from './dashboard/Sessions';
import ProfileCard from './dashboard/Profile';
import QuestionnairesCard from './dashboard/Questionnaires';

const CouchPNG = require('../assets/card_images/couch.png');


function DashboardInner() {

    const auth = useAuth();

    const userRolesStr = window.localStorage.getItem("roles");
   
    

    return <Container className="mt-5">
        <Row className='mb-3 justify-content-md-left'>
            <Col md={12} >
                <ProfileCard className=""  />
            </Col>

            {/* <Col md={4} sm={12}>
                <Card style={{padding: 1}} bg="primary">
                        <Card.Img src={CouchPNG} />
                   
                </Card>
            </Col> */}
        </Row>
        {userRolesStr?.match(/patient/) && 
        <Row className="mb-3">
            <Col md={6}  sm={12} className="mb-3">
                 <SessionCard  />
            </Col>
            <Col md={6}  sm={12}>
                 <QuestionnairesCard  />
            </Col>
        </Row>
        }
    </Container>
}




function PatientDashboard({bgUrl, bgOpacity=0.2} : {bgUrl?: Path, bgOpacity: number}) {
    const auth = useAuth();
    const navigate = useNavigate();
   
    const [userData, setUserData] = useState<UserData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        //TODO May 22nd: do this with user/me ?? and then add measures?
        fetch(`${BASE_URL}/measures`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.localStorage.getItem("token")
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log('Completed questionnaires:', data);
                
                if (data && data.length > 0) {

                    setUserData({
                        questionnaireResults: data
                    } as UserData);
                  
                } else {
                    navigate('/questionnaire/assessment')
                }

            })
            .catch((error) => {
                console.error('Error loading completed questionnaires:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    return (
        <AbsBG url={bgUrl as (string | undefined)} opacity={bgOpacity}>
        <div className="orfeo-patient-dashboard" style={{ position: "relative", minHeight: "100vh" }} >
        {isLoading ? <Spinner animation="border" variant="primary" /> : <DashboardInner />}
            


        </div>
        </AbsBG>
    );
}

export default PatientDashboard;


