import React from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { useControlledStateSetter } from '../helpers/hooks';
import { useDatatableWrapper } from './DatatableWrapper';
import FontAwesome from './FontAwesome';
/**
 * Renders a control for the text filtering. This component is
 * only rendered when at least one of the headers have `isFilterable`
 * prop. Otherwise, this component will return `null`.
 */
export function Filter({ placeholder = 'Enter text...', classes, controlledProps }) {
    const { filterState: filterStateContext, onFilterChange: onFilterChangeContext, isFilterable } = useDatatableWrapper();
    useControlledStateSetter(controlledProps);
    if (!isFilterable) {
        return null;
    }
    /**
     * Controlled has the bigger priority.
     * Supports @deprecated onFilter
     */
    const onFilterChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onFilterChange) ||
        (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onFilter) ||
        onFilterChangeContext;
    const filterState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filter) || filterStateContext;
    return (React.createElement(InputGroup, { className: classes === null || classes === void 0 ? void 0 : classes.inputGroup },
        React.createElement(Form.Control, { type: "text", name: "table-filter", value: filterState, placeholder: placeholder, onChange: (e) => onFilterChange(e.target.value), className: classes === null || classes === void 0 ? void 0 : classes.input }),
        React.createElement(Button, { onClick: () => onFilterChange(''), className: classes === null || classes === void 0 ? void 0 : classes.clearButton, "aria-label": "Clear filter" },
            React.createElement(FontAwesome, { icon: "times", className: "fa-fw" }))));
}
