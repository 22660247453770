const SRS = [
    {
        "id": "SRS-1",
        "title": "Session Rating Scale",
        "options": [],
        "type": "Text",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "SRS-2",
        "title": "Relationship",
        "options": [
            "I did not feel heard, understood, and respected.",
            "I felt somewhat unheard, misunderstood, and disrespected.",
            "I felt neither heard nor unheard, understood nor misunderstood, respected nor disrespected.",
            "I felt mostly heard, understood, and respected.",
            "I felt heard, understood, and respected."
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "SRS-3",
        "title": "Goals and topics",
        "options": [
            "We did not work on or talk about what I wanted to work on and talk about.",
            "We rarely worked on or talked about what I wanted to work on and talk about.",
            "Sometimes we worked on and talked about what I wanted, sometimes we didn't.",
            "We mostly worked on and talked about what I wanted to work on and talk about.",
            "We worked on and talked about what I wanted to work on and talk about."
     
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "SRS-4",
        "title": "Approach and method",
        "options": [
            "The therapist's approach is not a good fit for me.",
            "The therapist's approach is somewhat a mismatch for me.",
            "I am neutral about the therapist's approach, neither a good fit nor a bad fit.",
            "The therapist's approach is mostly a good fit for me.",
            "The therapist's approach is a good fit for me."
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "SRS-5",
        "title": "Overall",
        "options": [
            "There was something missing in the session today.",
            "The session was mostly acceptable, but I felt something was slightly missing.",
            "The session was neither completely right nor was there something missing.",
            "The session was mostly right for me, with minor things missing.",
            "Overall, today's session was right for me."
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    }
]

export default SRS