import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../config';
import { Measure, Session, SessionListProps } from './types';
import { Card, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';


const headers = [
    { title: 'Name', prop: 'key' },
    { title: 'Completed At', prop: 'updated_at' }
];

export default function QuestionnairesCard(props: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [sessions, setSessions] = useState<Measure[]>([]);

    // Fetch from /sessions endpoint 
    useEffect(() => {
        setIsLoading(true);
        fetch(`${BASE_URL}/measures?_start=0&_end=10&_sort=updated_at&_order=ASC`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0)
                    setSessions(data);
            }
            )
            .catch((error) => {
                console.error('Error loading sessions:', error);
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])


    return (
        <Card className={props.className || ''} text="dark">
            <Card.Header as="h5">Completed Assessments</Card.Header>
            <Card.Body>
                {isLoading ? <div>Loading...</div> : <div>
                    <DatatableWrapper  body={sessions.map((session: Measure) => { return {...session, updated_at: moment(session.updated_at).format('MM/DD/YYY')}} )} headers={headers}>
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                            >
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                            </Col>
                        </Row>
                        <Table>
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </DatatableWrapper>
                </div>
                }
            </Card.Body>
        </Card>
    );
}

