import React from 'react';
import { getNextCheckboxState } from '../helpers/checkbox';
import { useDatatableWrapper } from './DatatableWrapper';
/**
 * Renders a control for selection or deselection of all rows. This is
 * only useful when pagination and checkbox are both enabled. When only
 * some (or none) of the rows are checked, then this will render the
 * number of checked rows, as well as the "Select all" button.
 * On the other hand, when all rows are selected, then it will
 * change to "Deselect all" button.
 */
export function BulkCheckboxControl({ controlledProps, classes }) {
    const { checkboxState: checkboxStateContext, onCheckboxChange: onCheckboxChangeContext, filteredDataLength: filteredDataLengthContext, previouslyModifiedCheckbox, checkboxRefs, body } = useDatatableWrapper();
    const checkboxState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.checkboxState) || checkboxStateContext;
    const onCheckboxChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onCheckboxChange) || onCheckboxChangeContext;
    const filteredDataLength = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filteredDataLength) || filteredDataLengthContext;
    const previouslyUpdatedCheckbox = checkboxState[previouslyModifiedCheckbox.prop];
    const state = previouslyUpdatedCheckbox === null || previouslyUpdatedCheckbox === void 0 ? void 0 : previouslyUpdatedCheckbox.state;
    let rendered;
    function onClick(type) {
        onCheckboxChange({
            prop: previouslyModifiedCheckbox.prop,
            idProp: previouslyModifiedCheckbox.idProp,
            checkboxRefs,
            nextCheckboxState: getNextCheckboxState({
                checkboxState,
                data: body,
                filteredDataLength,
                idProp: previouslyModifiedCheckbox.idProp,
                prop: previouslyModifiedCheckbox.prop,
                type
            })
        });
    }
    const buttonClassName = (classes === null || classes === void 0 ? void 0 : classes.selectRemoveAllText) || 'text-primary p-0 border-0 bg-transparent';
    if (state === 'all-selected') {
        rendered = (React.createElement(React.Fragment, null,
            "All ",
            filteredDataLength,
            " rows selected.",
            ' ',
            React.createElement("button", { type: "button", tabIndex: 0, onClick: () => onClick('remove'), className: buttonClassName }, "Deselect all rows")));
    }
    else if (state === 'some-selected') {
        rendered = (React.createElement(React.Fragment, null, previouslyUpdatedCheckbox === null || previouslyUpdatedCheckbox === void 0 ? void 0 :
            previouslyUpdatedCheckbox.selected.size,
            " rows selected.",
            ' ',
            React.createElement("button", { type: "button", tabIndex: 0, onClick: () => onClick('add'), className: buttonClassName }, "Select all rows")));
    }
    else {
        // Zero-width space.
        rendered = React.createElement("span", null, "\u200B");
    }
    return (React.createElement("div", { className: rendered === undefined ? 'invisible' : undefined }, rendered));
}
