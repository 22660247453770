import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as types from "../../types";
import get from "lodash/get";

export const Question = (
    { question, handleAnswerClick,
        handleNextClick, handleSubmit,
        answers, currentQuestionIndex }: {
            question: types.Question;
            handleSubmit: (navigateTo?: string) => void;
            handleNextClick: () => void;
            handleAnswerClick: (v: { option?: any; value?: any; question: types.Question }) => void;
            answers: Record<string | number,
                { answer: { option?: any; value?: any; }; }>; currentQuestionIndex: number;
        }) => {
    const { id, title, options, type, element, min, max, placeholder, conditions, values, noNav } = question;

    const [selected, isSelected] = React.useState<boolean>(false)
    const [settingValue, setSettingValue] = React.useState<any>({})


    if (conditions && conditions?.length > 0) {
        const { target, conditional, dependency, conditionCase } = conditions[0];
        if (answers[dependency]?.answer !== conditionCase) {
            handleAnswerClick({
                question
            });
            if (type !== "checkboxes")
                handleNextClick();
            return null;
        }
    }


    if (type === "element") {
        return element({ 
            onChange: (c, action: { "navigate": string; } | undefined) => handleAnswerClick({ value: c, question }),
            handleAnswerClick,
            handleNextClick,
            handleSubmit,
            answer: answers[id]?.answer 
        });
    }
    else if (type === "select" || type === "radio" || type === "Likert") {
        return <>{options && options.length > 1 && options.map((option, index) => (
            <Button
                key={index}
                variant={((answers[id]?.answer.option) === option ||  settingValue[index])  ? "success" : "primary"}
                onClick={(e) => {
                    e.preventDefault();
                    if (settingValue[index]) return;
                    const answer = { option, question };
                    console.log(index, answer, values)
                    if (values && values[index] !== undefined)
                        answer["value"] = values[index];
                    setSettingValue({...settingValue, [index]: true})
                    setTimeout(() => {
                        handleAnswerClick(answer);
                        handleNextClick();
                        setSettingValue({...settingValue, [index]: false})
                    }, 200);
                }}

                className="questionnaire-option mb-5"
            >
                {settingValue[index] ? <Spinner size="sm" /> : option}
            </Button>
        ))}</>;
    }
    else if (type === "checkboxes") {
        const checked = (option: any) => get(answers, `${id}.answer.option`, []).includes(option)
        const appendOrInit = (option: any) => {
            console.log("Appending")
            return [...(answers[id]?.answer?.option || []), option];
        }
        // User can select multiple options
        return <>
            <i>Select all options that apply</i>
            {options && options.length > 1 && options.map((option, index) => (
                // <Form.Check
                //     key={index}
                //     type="checkbox"
                //     label={option}

                //     checked={checked(option)}
                //     onChange={() => {
                //         console.log("CHECKED", checked(option))
                //         if (!checked(option)) {
                //             handleAnswerClick({ option: appendOrInit(option), question })
                //         }
                //         else {
                //             const newOptions = answers[id]?.answer?.option?.filter((o: any) => o !== option);
                //             handleAnswerClick({ option: newOptions, question })
                //         }
                //     }}
                //     className="questionnaire-option mb-5"
                // />
                <div
                    key={index}
                    className={`questionnaire-option mb-5 ${checked(option) ? 'bg-success' : 'bg-primary'}`}
                    style={{ borderRadius: '50px', padding: '0.5rem', display: 'inline-block', color: (checked(option) ? 'black' : 'white') }}

                    onClick={() => {
                        if (!checked(option)) {
                            handleAnswerClick({ option: appendOrInit(option), question });
                        } else {
                            const newOptions = answers[id]?.answer?.option?.filter((o) => o !== option);
                            handleAnswerClick({ option: newOptions, question });
                        }
                    }}
                >
                    <Form.Check
                        className="orfeo-form-check-input"
                        type="checkbox"
                        label={option}
                        checked={checked(option)}

                        onChange={() => {
                            // Empty onChange since we're handling it in the div's onClick
                        }}
                    />
                </div>
            ))}
        </>;
    }
    else if (type == 'text') {
        return <Form.Control
            key={id + '_text'}
            type="text"
            size="lg"
            style={{ borderRadius: "2em" }}
            placeholder={placeholder}
            value={answers[id]?.answer ? answers[id]?.answer.value || "" : ""}
            onChange={(e) => handleAnswerClick({ value: e.target.value, question })} />;
    }
    else if (type == "number") {
        return <>
            <Form.Group>
                <Form.Control
                    key={id + '_number'}
                    size="lg"
                    style={{ borderRadius: "2em" }}
                    type="number"
                    placeholder={placeholder ? placeholder : "Enter a number"}
                    min={min}
                    max={max}

                    value={answers[id]?.answer.value == undefined ? undefined : answers[id]?.answer.value}
                    onChange={(e) => handleAnswerClick({ "value": Number(e.target.value), question })} />
            </Form.Group>
        </>;
    }
    else {
        return <></>;
    }
};
