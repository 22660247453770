var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form } from 'react-bootstrap';
import { useDatatableWrapper } from './DatatableWrapper';
import FontAwesome from './FontAwesome';
import { makeClasses } from '../helpers/object';
import { getNextCheckboxState } from '../helpers/checkbox';
import { getNextSortState } from '../helpers/data';
import { useControlledStateSetter } from '../helpers/hooks';
/**
 * Renders a list of table headers.
 */
export function TableHeader({ classes, controlledProps }) {
    const headings = [];
    const { headers, onSortChange: onSortChangeContext, sortState: sortStateContext, onCheckboxChange: onCheckboxChangeContext, checkboxState: checkboxStateContext, checkboxRefs, filteredDataLength: filteredDataLengthContext, data } = useDatatableWrapper();
    useControlledStateSetter(controlledProps);
    const onSortChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onSortChange) || onSortChangeContext;
    const sortState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.sortState) || sortStateContext;
    const onCheckboxChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onCheckboxChange) || onCheckboxChangeContext;
    const checkboxState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.checkboxState) || checkboxStateContext;
    const filteredDataLength = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filteredDataLength) || filteredDataLengthContext;
    for (let i = 0; i < headers.length; i += 1) {
        const { isSortable, prop: rawProp, title, headerCell, thProps: additionalThProps = {}, checkbox, alignment } = headers[i];
        const prop = rawProp.toString();
        const { className: headerPropsClassName } = additionalThProps, rest = __rest(additionalThProps, ["className"]);
        const thClass = makeClasses({
            'thead-th': true,
            sortable: isSortable === true
        });
        const isCurrentSort = sortState.prop === prop;
        const thProps = Object.assign({ key: `th-${i}`, className: makeClasses(thClass, classes === null || classes === void 0 ? void 0 : classes.th, headerPropsClassName, 
            // Alignment.
            {
                'text-start': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'left',
                'text-center': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'center',
                'text-end': (alignment === null || alignment === void 0 ? void 0 : alignment.horizontal) === 'right'
            }) }, rest);
        let sortIcon = 'sort';
        let sortIconRender = null;
        if (isSortable && !checkbox) {
            if (isCurrentSort) {
                // Depending on the sort order, add a `data-sort-order` attribute,
                // which is mostly for testing, as well as setting the icons and
                // the next sort type which will be used in the on click event.
                thProps['data-sort-order'] = sortState.order;
                if (sortState.order === 'asc') {
                    sortIcon = 'sortUp';
                }
                else {
                    sortIcon = 'sortDown';
                }
            }
            thProps.onClick = () => onSortChange(getNextSortState(sortState, prop));
            thProps.role = 'button';
            sortIconRender = React.createElement(FontAwesome, { icon: sortIcon, className: "fa-fw" });
        }
        let rendered;
        if (checkbox) {
            let numberOfSelectedRowsInCurrentPage = 0;
            let nextCheckboxType;
            for (const row of data) {
                if (checkboxState[prop].selected.has(row[checkbox.idProp])) {
                    numberOfSelectedRowsInCurrentPage += 1;
                }
            }
            if (numberOfSelectedRowsInCurrentPage === data.length) {
                nextCheckboxType = 'remove';
            }
            else {
                nextCheckboxType = 'add';
            }
            // Source for using visually hidden: https://www.w3.org/WAI/tutorials/forms/labels/#hiding-the-label-element.
            // TODO(imballinst): show the number of currently selected rows in the label.
            rendered = (React.createElement(Form.Group, { controlId: `table-selection-all` },
                React.createElement(Form.Label, { className: "visually-hidden" }, nextCheckboxType === 'add'
                    ? `Add ${data.length - numberOfSelectedRowsInCurrentPage} rows to selection`
                    : `Remove ${numberOfSelectedRowsInCurrentPage} rows from selection`),
                React.createElement(Form.Check, { type: "checkbox", name: "table-selection", value: "all", className: checkbox.className, checked: checkboxState[prop].state === 'all-selected', ref: (node) => {
                        if (node !== null) {
                            checkboxRefs.current[prop] = node;
                        }
                    }, onChange: () => {
                        onCheckboxChange({
                            prop,
                            idProp: checkbox.idProp,
                            nextCheckboxState: getNextCheckboxState({
                                checkboxState,
                                data,
                                idProp: checkbox.idProp,
                                filteredDataLength,
                                prop,
                                type: nextCheckboxType
                            }),
                            checkboxRefs
                        });
                    } })));
        }
        else if (headerCell) {
            rendered = headerCell(sortIconRender, sortState);
        }
        else {
            rendered = (React.createElement(React.Fragment, null,
                title,
                React.createElement("span", null, sortIconRender)));
        }
        headings.push(React.createElement("th", Object.assign({}, thProps), rendered));
    }
    return (React.createElement("thead", { className: makeClasses('thead', classes === null || classes === void 0 ? void 0 : classes.thead) },
        React.createElement("tr", { className: makeClasses('thead-tr', classes === null || classes === void 0 ? void 0 : classes.tr) }, headings)));
}
