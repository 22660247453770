import React, { useEffect, useState } from 'react';
import capitalCase from 'lodash.startcase';
import template from 'lodash.template'
import { BASE_URL, BRAND } from '../../config';
import { ProfileProps, Profile } from './types';

import moment from 'moment'
import 'moment-timezone'

import { Card, Spinner } from 'react-bootstrap';

/*
- introduction
- dashboard
- questionnaire (reminders)
- post-session ORS
- post-session SRS.
*/

const profileContent = {
    "new_candidate": {
        "title": (props: any) => <>Welcome to the Dérive Ketamine Assisted Treatment program!</>,
        "content": (props: any) => <Card.Text>
            <p>Thank you for your interest in our program. You have completed the initial assessment, and our team is now reviewing your results.</p>
            <p>We will let you know within the next few days if we are able to customize a treatment plan for you and accept you as a patient in our pilot program.</p>
            <b>Next steps</b>
            <p>Once the clinic reviews your assessment, we will reach out to schedule your ketamine infusion therapy sessions.</p>
            <p>Your customized treatment plan will incorporate your assessment &amp; help us learn more:</p>
            <ul>
                <li>A planned ketamine infusion sessions</li>
                <li>Continued access to the portal to support you on your journey</li>
            </ul>
            <p>We will send you reminders to complete important questionnaires before and after your sessions to track your progress.</p>
            <p>You will be able to view your scheduled sessions, progress reports, and questionnaire results through the online patient portal.</p>
            <p>During treatment, our team will monitor your progress and make any needed adjustments to optimize your treatment plan based on your goals.</p>
            <p>We look forward to potentially working with you and accompanying you on your journey toward healing and growth.</p>
        </Card.Text>
    },
    "welcome": {
        "title": (props: any) => <>Introduction to Dérive Ketamine Assisted Treatment Program!</>,
        "content": (props: any) => <Card.Text>
            <p>On behalf of Dérive, we would like to extend a warm welcome to you and introduce you to our innovative Ketamine Assisted Treatment Program. We believe this program has the potential to bring about positive and transformative changes in your life.
            </p>
            <p>
                As you may already be aware, our program focuses on the administration of ketamine, a psychedelic medicine that has shown promising results in the treatment of various mental health conditions. Before proceeding further, we would like to emphasize that our primary goal is to improve your well-being and support you on your journey toward lasting recovery.
            </p>
            <p>
                To ensure that you receive the best possible care, we have designed a comprehensive process that begins with an initial interview conducted by one of our skilled medical doctors. During this interview, Dr. Leake will discuss your medical history, mental health concerns, and any previous treatment experiences. This dialogue is crucial for us to understand your unique needs and determine the suitability of ketamine assisted treatment for you.
            </p>
            <p>
                Once you have successfully completed the eligibility check, we will be delighted to enroll you in our program. Alongside this letter, you will receive a patient package that contains detailed information about the program, its various components, and what you can expect throughout the treatment journey. We have made every effort to include comprehensive information that addresses frequently asked questions, treatment protocols, potential benefits, and potential risks associated with ketamine assisted treatment. We encourage you to review this material carefully and feel free to reach out to our team if you have any additional questions or concerns.
            </p>
            <p>
                At Dérive, we believe in a patient-centered approach, which means that your well-being and comfort are our top priorities. Our highly trained and compassionate medical professionals will be with you every step of the way, offering guidance, support, and personalized care throughout the treatment process. We are committed to ensuring that you have a safe, confidential, and respectful environment where you can embark on your journey.
            </p>
            <p>
                We understand that embarking on a new treatment path can be both exciting and overwhelming, and we want you to know that we are here for you. Please do not hesitate to reach out to our team at 980-221-2689 or via email at info@derivehealth.com if you require any further information, have any questions, or would like to schedule your initial interview. Our dedicated staff is available to assist you and provide the guidance you need.
            </p>
            <p>
                We are honored to have the opportunity to be part of your journey and look forward to working together to achieve the positive outcomes you deserve.
            </p>
        </Card.Text>

    },
    "upcoming_session": {
        "title": (props: any) => <>Upcoming Session</>,
        "content": (props: { scheduled_at: string }) => <>
            <p>Your next session is scheduled for {props.scheduled_at}.</p>
            <p>Please arrive 15 minutes early to your session. If you need to reschedule, please contact us</p>
        </>
    },
}

const templateSettings = { interpolate: /{{([\s\S]+?)}}/g, evaluate: /{{=([\s\S]+?)}}/g, escape: /{{-([\s\S]+?)}}/g, imports: { window: undefined, location: undefined, localStorage: undefined, Navigator: undefined} }


export default function ProfileCard(props: ProfileProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [profile, setProfile] = useState<Profile | undefined>(undefined);
    const [content, setContent] = useState<{
        title: (props: any) => JSX.Element;
        content: (props: any) => JSX.Element;
        _note?: string;
    } | undefined>(undefined);


    // Fetch from /candidates/:id endpoint
    useEffect(() => {

        setIsLoading(true);
        fetch(`${BASE_URL}/profile`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("token")
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Profile:', data);
                setProfile(data);
            })
            .catch((error) => {
                console.error('Error loading profile:', error);
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    useEffect(() => {
        setIsLoading(true);


        const userRolesStr = window.localStorage.getItem("roles");
        let defaultContent = profileContent["new_candidate"];
        let slug = "new_candidate"
        try {
            const userRoles = JSON.parse(userRolesStr || "[]");
            if (userRoles.includes('patient')) {
                console.log("Patient")
                defaultContent = profileContent["welcome"];
                slug = "patient-welcome"
            }
            if (profile?.next_upcoming_session && profile?.next_upcoming_session_tz) {
                slug = "upcoming-session"
            }
        } catch (e) {
            console.log("Error parsing roles", userRolesStr);
        }
        defaultContent = profileContent["new_candidate"];

        fetch(`${BASE_URL}/content/patient/${slug}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then((data: { slug: string, title: string, body: string }) => {
            console.log('Content:', data);
            
            const newContent = {
                title: (props: any) => <div dangerouslySetInnerHTML={{__html: data.title}} />,
                content: (props: {profile: Profile}) => <Card.Text dangerouslySetInnerHTML={{__html: template(data.body, templateSettings)({
                    scheduled_at: moment.tz(props.profile?.next_upcoming_session, props.profile?.next_upcoming_session_tz).format('MM/DD/YYYY hh:mm A z'),
                }) }} />,
                _note: data.slug
            }
            setContent(newContent);
        })
        .catch(() => {
            setContent(defaultContent);
        })
        .finally(() => {
            setIsLoading(false);
        })

    }, [profile])

    console.log("Content", content)
    return (
        <Card className={props.className || ''} style={{}} bg="primary" text="white">
            {content ? <><Card.Header>
                            <Card.Title><content.title /></Card.Title>
                        </Card.Header>
                        <Card.Body style={{}}>
                     
                                <content.content profile={profile} />
                            
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-white">Email: {profile?.email}</small>
                        </Card.Footer></>
             : <><Card.Header>Loading...</Card.Header>
                <Card.Body>
                    <Spinner />
                </Card.Body>
             </>
            }
        </Card>
    )
}