export default [
    // {
    //     "id": "beaq-0",
    //     "title": "Brief Experiential Avoidance Questionnaire",
    //     "options": [],
    //     "type": "Text",
    //     "values": [],
    //     "conditions": []
    // },
    {
        "id": "beaq-1",
        "title": "The key to a good life is never feeling any pain",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-2",
        "title": "I am quick to leave any situation that makes me feel uneasy",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-3",
        "title": "When unpleasent memories come to me, I try to put them out of my mind",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-4",
        "title": "I feel disconnected from my emotions",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-5",
        "title": "I won't do something until I absolutely have to",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-6",
        "title": "Fear or Anxiety won't stop me from doing something important",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-7",
        "title": "I would give up a lot not to feel bad",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-8",
        "title": "I rarely do something if there is a chance that it will upset me",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-9",
        "title": "It is hard for me to know what I am feeling",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-10",
        "title": "I try to put off unpleasant tasks for as long as possible",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-11",
        "title": "I go out of my way to avoid uncomfortable situations",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-12",
        "title": "One of my big goals it to be free from painful emotions",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-13",
        "title": "I work hard to keep out upsetting feelings",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-14",
        "title": "If I have any doubts about doing someting, I just won't do it",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
    {
        "id": "beaq-15",
        "title": "Pain always leads to suffering",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    }
]