export default [
   
    {
        "id": "phq-1",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Little interest or pleasure in doing things?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-2",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Feeling down, depressed, or hopeless?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-3",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Trouble falling or staying asleep, or sleeping too much?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-4",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Feeling tired or having little energy?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-5",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Poor appetite or overeating?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-6",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Feeling bad about yourself—or that you are a failure or have let yourself or your family down?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-7",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Trouble concentrating on things, such as reading the newspaper or watching television?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-8",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle":"Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have moving around a lot more than usual?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    },
    {
        "id": "phq-9",
        "title": "Over the last 2 weeks, how often have you been bothered by any of the following?",
        "subTitle": "Thoughts that you would be better off dead or of hurting yourself in some way?",
        "options": [
            "Not at all",
            "Several days",
            "More than half the days",
            "Nearly every day"
        ],
        "type": "Likert",
        "format": "Numerical",
        "values": [
            0,
            1,
            2,
            3
        ],
        "conditions": []
    }
];