import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Spinner } from 'react-bootstrap';
import startCase from 'lodash/startCase';


import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { BRAND } from '../config';

export default function Navigation({logo} : {logo: React.ReactNode}) {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const authCtx = useAuth()
  // const [isOpen, setIsOpen] = React.useState(false);

  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };
  return (

    <Navbar className="orfeo-nav sticky-top" expand="lg" >
      <Container>
        <Navbar.Brand href="#/">
          <div className="nav-logo">
            {logo ? logo : <img className="nav-logo-img" src={logo as string} />}
            <span>{startCase(BRAND)}</span>
          </div>

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          
          {/* {authCtx.currentUser ? <>
           <Nav className="">
            <Nav.Link href="#/">Home</Nav.Link>
          </Nav>
          <Nav className="">
            <Nav.Link href="#/journey">Journey</Nav.Link>
          </Nav>
          <Nav className="">
            <Nav.Link href="#/sessions">Sessions</Nav.Link>
          </Nav>
          <Nav className="">
            <Nav.Link href="#/providers">Care Team</Nav.Link>
          </Nav>  </> : <></>} */}
          <Nav className="me-auto">
            {/* <Nav.Link href="#/">Home</Nav.Link> */}
            {/* <Nav.Link href="#/questionnaire">Questionnaires</Nav.Link> */}
            {authCtx.currentUser ? <Nav.Link disabled={isLoading} onClick={() => {
              setLoading(true);
              window.localStorage.removeItem("token");
              window.localStorage.clear();
              setLoading(false);
              navigate('/signup')
              window.location.reload();
            }}>Logout {isLoading ? <Spinner /> : <></>}</Nav.Link> : <Nav.Link href="#/signup">Login</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
