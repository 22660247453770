import React from 'react';
import { Form } from 'react-bootstrap';
import { useControlledStateSetter } from '../helpers/hooks';
import { makeClasses } from '../helpers/object';
import { useDatatableWrapper } from './DatatableWrapper';
/**
 * Renders a control for the pagination options. It will show a form group, consisting of
 * a select input, as well as a label/text before and after the input. By default,
 * it renders "Rows per page" as a label, which refers to the select input. There is no
 * text after the select input, unless specified in the `labels.afterSelect` prop.
 *
 * The options available in the select input are the ones specified from the `DatatableWrapper`,
 * or the ones passed in this component's prop `controlledProps.rowsPerPageOptions`.
 *
 * When `alwaysShowPagination` is set to `false`, then this component will be visually hidden.
 */
export function PaginationOptions({ labels, classes, alwaysShowPagination = true, controlledProps }) {
    const { onRowsPerPageChange: onRowsPerPageChangeContext, rowsPerPageOptions: rowsPerPageOptionsContext, rowsPerPageState: rowsPerPageStateContext, filteredDataLength: filteredDataLengthContext } = useDatatableWrapper();
    useControlledStateSetter(controlledProps);
    // Controlled has the bigger priority.
    const rowsPerPageOptions = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.rowsPerPageOptions) || rowsPerPageOptionsContext;
    const rowsPerPageState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.rowsPerPage) || rowsPerPageStateContext;
    const filteredDataLength = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.filteredDataLength) || filteredDataLengthContext;
    const onRowsPerPageChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onRowsPerPageChange) || onRowsPerPageChangeContext;
    // Hide pagination if pagination is meant to not always be shown or
    // if the filtered data length is less than the minimum rows per page option.
    const hidePaginationOptions = !alwaysShowPagination &&
        filteredDataLength <= Math.min(...rowsPerPageOptions);
    return (React.createElement(Form.Group, { controlId: "formGroupPagination", className: makeClasses('paginationOpts__root', classes === null || classes === void 0 ? void 0 : classes.formGroup, {
            invisible: hidePaginationOptions
        }) },
        React.createElement(Form.Label, { className: classes === null || classes === void 0 ? void 0 : classes.formText }, (labels === null || labels === void 0 ? void 0 : labels.beforeSelect) || 'Rows per page'),
        React.createElement(Form.Select, { name: "table-pagination-options", value: rowsPerPageState, as: "select", placeholder: "select", onChange: (e) => onRowsPerPageChange(Number(e.target.value)), className: classes === null || classes === void 0 ? void 0 : classes.formControl }, rowsPerPageOptions.map((option) => {
            const optionProps = {
                key: `page-opt-${option}`,
                value: option
            };
            return React.createElement("option", Object.assign({}, optionProps), option);
        })),
        (labels === null || labels === void 0 ? void 0 : labels.afterSelect) && (React.createElement(Form.Text, { className: classes === null || classes === void 0 ? void 0 : classes.formText }, labels === null || labels === void 0 ? void 0 : labels.afterSelect))));
}
