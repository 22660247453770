// EmailSignupForm.tsx
import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert, InputGroup, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import AbsBG from "../components/helpers/AbsBg";

const Signup = ({bgUrl, bgOpacity=0.2} : {bgUrl: Path, bgOpacity: number}) => {
    const location = useLocation();
    const state = location.state || {};
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(state.message);
    const [error, setError] = useState(state.error);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axios.post(`${BASE_URL}/auth/magiclink`, { email });
            window.localStorage.setItem("emailForSignIn", email);
            setMessage("Sign-up link sent to your email. Please check your inbox.");
            setError("");
        } catch (error) {
            setError(`Error: ${error.message}`);
            setMessage("");
        }
        setIsLoading(false)
    };

    return (
    <AbsBG opacity={1} blur={true} opacity={bgOpacity} url={bgUrl}>
    <Container fluid className="app-container" style={{ minHeight: "100vh" }}>
        <Row className="justify-content-center align-items-center min-vh-100">
            <Col xs={12} sm={8} md={4} lg={4} className="p-5" style={{ borderRadius: "10px", backgroundColor:"rgba(255,255,255,0.7)", color: "black" }}>
                <Row>
                    <h1>Sign In</h1>
                    <p>Enter your email to get access to your portal</p>
                </Row>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail">
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Button variant="primary" type="submit" disabled={isLoading} className="mt-3"> 
                                    {isLoading ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        "Send Magic Link"
                                    )}
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Row>
                <Row>
                 <Col>
                 {message && (
                        <Alert variant="primary" className="mt-3" style={{padding: "0.25rem 1rem"}}>
                            {message}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" className="mt-3" style={{padding: "0.25rem 1rem"}}>
                            {error}
                        </Alert>
                    )}
                </Col>
                </Row>
            </Col></Row>
        
    </Container>
    </AbsBG>
    );
};

export default Signup;
