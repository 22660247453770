import { createContext, useContext, useEffect, useState, useMemo} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

interface User {
  // define user properties here based on your API response
  // for example:
  id: string;
  roles: string[];
  email?: string;
}

const AuthContext = createContext<{"currentUser" : User | null}>({"currentUser": null });

export const useAuth = () : {currentUser: User}  => {
  return useContext(AuthContext) as {currentUser: User};
};

function useQuery() {
  const search = window.location.search;

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const AuthProvider = ({ children }) => {
  const {search} = useLocation();

  const navigate = useNavigate();
  const hardNavigate = (hash: string, redirectTo?: string) => {
   
    window.location.href = `${window.location.origin}/${redirectTo? '?redirect_to=' + redirectTo : ''}#${hash}`
    
  } 
  const query = useQuery();
  const token = query.get('token')
  const session_id = query.get('session_id')
  const redirect_to = query.get('redirect_to')
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      // call http://localhost:8080/auth/magiclink/verify?token=...
      // if successful, set the user and redirect to /onboarding
      // if not successful, redirect to /signup with error message
      fetch(`${BASE_URL}/auth/magiclink/verify?token=${token}`)
        .then(response => {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        } ) 
        .then(data => {
          // Store the bearer token in local storage
          window.localStorage.setItem("token", data.token);
          if (session_id) {
            console.log("Setting session id", session_id);
            window.localStorage.setItem("current_session_id", session_id);
          }
         
          //window.location.href = window.location.origin;
          // redirect to /onboarding
          console.log("REDIRECTING TO", redirect_to)
          //debugger
          if (redirect_to)
            hardNavigate(redirect_to)
          else
            hardNavigate('/')
        })
        .catch(error => {
         // debugger
          window.localStorage.removeItem("token");
          if (window.localStorage.getItem("current_session_id"))
            window.localStorage.removeItem("current_session_id");
         // window.localStorage.clear()
          window.location.search = "";
          window.location.hash = "";
          console.error("Failed to verify magic link:", error);
          // redirect to /signup with error message
        });

    }
    else {
      fetch(`${BASE_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
  
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then((data: {id: string, meta: any, roles: string[], token:string}) => {
          setCurrentUser(data);
          window.localStorage.setItem("userId", data.id);
          window.localStorage.setItem("roles", JSON.stringify(data.roles));
          if (data.token)
            window.localStorage.setItem("token", data.token);
  
          if (session_id) {
            window.location.search = "";
            console.log("Setting session id", session_id);
            window.localStorage.setItem("current_session_id", session_id);
          }
          
          setLoading(false);
          if (data) {
            console.log("We have a user", data);
            console.log("TRYING TO GO TO SHIT");
            // debugger
            // if (data.roles.includes('patient')) {
            //   console.log("")
            //   navigate(redirect_to ? redirect_to : '/')
            // }
            // navigate('/onboarding')
          }
        })
        .catch(error => {
          console.error("Failed to fetch user data:", error);
          setLoading(false);
        });
  
      }

  }, [token])

  // useEffect(() => {
    
  //   console.log("what?", token, query.get('token'))
   

  //   return () => {};

  // }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
  
      {!loading && children}
    </AuthContext.Provider>
  );
};