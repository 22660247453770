import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useControlledStateSetter } from '../helpers/hooks';
import { makeClasses } from '../helpers/object';
import { useDatatableWrapper } from './DatatableWrapper';
/**
 * Renders a control for the pagination. It consists of "First", "Prev",
 * "Next", and "Last" buttons. Additionally, between the "Prev" and "Next" buttons,
 * there will be at maximum 3 numbered buttons, indicating the pages to navigate.
 *
 * The currently active page will always be disabled, as well as the "First" or "Last"
 * buttons if the `currentPage` represents the first page and last page, respectively.
 *
 * When `alwaysShowPagination` is set to `false`, then this component will be visually hidden.
 */
export function Pagination({ labels, classes, alwaysShowPagination = true, controlledProps }) {
    const { currentPageState: currentPageStateContext, maxPage: maxPageContext, onPaginationChange: onPaginationChangeContext } = useDatatableWrapper();
    useControlledStateSetter(controlledProps);
    // Controlled has the bigger priority.
    const currentPageState = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.currentPage) || currentPageStateContext;
    const maxPage = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.maxPage) || maxPageContext;
    const onPaginationChange = (controlledProps === null || controlledProps === void 0 ? void 0 : controlledProps.onPaginationChange) || onPaginationChangeContext;
    // Hide pagination if pagination is meant to not always be shown and the max page is 1,
    // or if rows per page is -1.
    const hidePagination = !alwaysShowPagination && maxPage === 1;
    const buttons = [];
    const firstLabel = (labels === null || labels === void 0 ? void 0 : labels.firstPage) || 'First';
    const prevLabel = (labels === null || labels === void 0 ? void 0 : labels.prevPage) || 'Prev';
    const nextLabel = (labels === null || labels === void 0 ? void 0 : labels.nextPage) || 'Next';
    const lastLabel = (labels === null || labels === void 0 ? void 0 : labels.lastPage) || 'Last';
    let startNumber;
    let i = 0;
    let hasPrev = true;
    let hasNext = true;
    if (currentPageState === 1) {
        // Active button is the first one.
        startNumber = 1;
        hasPrev = false;
        hasNext = maxPage > 1;
    }
    else if (currentPageState === maxPage && maxPage !== 1) {
        // Active button is in the last.
        startNumber = maxPage - 2 > 0 ? currentPageState - 2 : 1;
        hasNext = false;
    }
    else {
        // Active button is in the middle.
        startNumber = currentPageState - 1;
    }
    buttons.push(React.createElement(Button, { className: classes === null || classes === void 0 ? void 0 : classes.button, key: `page-${firstLabel}`, onClick: () => onPaginationChange(1), disabled: !hasPrev }, firstLabel), React.createElement(Button, { className: classes === null || classes === void 0 ? void 0 : classes.button, key: `page-${prevLabel}`, 
        // If out of bounds, prev button refers to the last page.
        onClick: () => onPaginationChange(currentPageState - 1), disabled: !hasPrev }, prevLabel));
    const pageNumbers = [];
    while (i < 3 && startNumber <= maxPage) {
        pageNumbers.push(startNumber);
        // Increment all.
        i += 1;
        startNumber += 1;
    }
    buttons.push(...pageNumbers.map((pageNumber) => (React.createElement(Button, { key: `page-btn-${pageNumber}`, onClick: () => onPaginationChange(pageNumber), disabled: currentPageState === pageNumber, className: classes === null || classes === void 0 ? void 0 : classes.button }, pageNumber))), React.createElement(Button, { className: classes === null || classes === void 0 ? void 0 : classes.button, key: `page-${nextLabel}`, onClick: () => onPaginationChange(currentPageState + 1), disabled: !hasNext }, nextLabel), React.createElement(Button, { className: classes === null || classes === void 0 ? void 0 : classes.button, key: `page-${lastLabel}`, onClick: () => onPaginationChange(maxPage), disabled: !hasNext }, lastLabel));
    return (React.createElement(ButtonGroup, { "aria-label": "Pagination button group", className: makeClasses('ButtonGroup__root', classes === null || classes === void 0 ? void 0 : classes.buttonGroup, {
            invisible: hidePagination
        }) }, buttons));
}
