import React, { useState } from "react";
import { ProgressBar, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import { useAuth } from "../hooks/useAuth";
import questionnaires from "../data/questionnaires";
import * as types from "../types";
import { Question } from "../components/questionnaire/Question";
import { NextBtnGrp } from "../components/questionnaire/NextBtnGrp";
import { v4 as uuidv4 } from "uuid";
import AbsBG from "../components/helpers/AbsBg";
import moment from "moment";

type SavedState = {
    id: string;
    state: any;
    createdAt: string;
}

const setPresistedState = (questionnaireId, savedState: SavedState) => {
    if (!savedState) {
        savedState = { state: {} };
    }
    if (!savedState.id) {
        savedState.id = uuidv4();
    }
    if (!savedState.createdAt)
        savedState.createdAt = new Date().toISOString();
    saveStateToLocalStorage(questionnaireId, savedState as SavedState);
};

const saveStateToLocalStorage = (questionnaireId, savedState: SavedState) => {

    localStorage.setItem(`questionnaire_${questionnaireId}`, JSON.stringify(savedState));
};

function clearPresistedState(questionnaireId) {
    localStorage.removeItem(`questionnaire_${questionnaireId}`);
}

const getStateFromLocalStorage = (questionnaireId: string): SavedState | {state: any} => {
    const storedState = localStorage.getItem(`questionnaire_${questionnaireId}`);
    if (storedState) {
      return JSON.parse(storedState);
    } else {
      return {state: {}};
    }
  };


const Questionnaire = ({bgUrl, bgOpacity=0.2} : {bgUrl?: Path, bgOpacity: number}) => {
    let { questionnaireId, step } = useParams();
    const questions: types.Question[] =  questionnaireId  ? questionnaires[questionnaireId] || questionnaires["initial"] : questionnaires["initial"];
    //const [currentQuestionIndex, setCurrentQuestionIndex] = useState(parseInt(step ? step : "0"));
    const presistedState = getStateFromLocalStorage(questionnaireId? questionnaireId : "initial");
    console.log("CURRENT PREISTED STATE", presistedState)
    const currentQuestionIndex = step ? parseInt(step) : 0;
    const [answers, setAnswers] = useState<Record<string, { questionId: string | number, answer: string | number | any }>>(presistedState ? presistedState.state : {});
    const CURRENTQUESTION = questions[step ? parseInt(step) : 0]
    console.log("CURRENT QUESTION", questionnaireId, step, questions, CURRENTQUESTION)
    const { id, title, options, type, element } = CURRENTQUESTION;
    
    const progress = ((currentQuestionIndex) / (questions.length - 1)) * 100;

    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleSubmit = (navigateTo?: string) => {
        setError(undefined);
        setIsLoading(true); // Set isLoading to true when update is in progress
      
        // Get the current user's ID
        const userId = auth.currentUser.id;
      
        // Prepare the data to send in the body of the POST request
        const data = {
          key: questionnaireId,
          answers: answers,
        };
      
        // Get the token from local storage
        const token = localStorage.getItem('token');
      
        // Make a POST request to 'http://localhost:8080/measures'
        const headers = {
            'Content-Type': 'application/json',
            // Add the Authorization header with the token from local storage
            'Authorization': `Bearer ${token}`
          }
          if ( window.localStorage.getItem('current_session_id') && window.localStorage.getItem('current_session_id') !== null)
            headers['current_session_id'] = window.localStorage.getItem('current_session_id')

        fetch(`${BASE_URL}/measures`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            clearPresistedState(questionnaireId);
            setAnswers({})
            console.log("NAVIGATING QUESTIONNAIRE TO", navigateTo)
            navigate(navigateTo ? navigateTo : '/');
            if (window.localStorage.getItem('current_session_id')) {
                console.log("REMOVING SESSION ID")
                window.localStorage.removeItem('current_session_id');
            }

          })
          .catch((error) => {
            console.error('Error submitting answers:', error);
            setIsLoading(false);
          })
          .finally(() => {
            clearPresistedState(questionnaireId);
            setAnswers({})
            setIsLoading(false); // Set isLoading back to false when update is complete
          });
      };

    const handleAnswerClick = (answer: {option?: any, value?:any}) => {
        const newAnswer = { questionId: id, answer, answeredAt: (moment.utc().toISOString()) }; // save in UTC
        answers[id] = newAnswer;
        
        setAnswers({ ...answers });

        const newPresistedState = { ...presistedState, state: { ...presistedState.state, [id]: newAnswer} }
        console.log("PRESISTING", newPresistedState)
        setPresistedState(questionnaireId, newPresistedState)
        //setTimeout(() => handleNextClick(), 70)
    };

    // React.useEffect(() => {
    //     setPresistedState(answers)
    // }, [answers]);


    const isComplete = currentQuestionIndex == questions.length - 1; //Object.keys(answers).length === questions.filter((e) => e.options && e.options.length > 0).length;

    const navigate = useNavigate();
    const handleNextClick = async () => {
        if (isComplete) {
            try {
           await handleSubmit()
        } catch (e) {
            
            setError(e.message)
        }
            //navigate("/onboarding_dashboard")
        }
        if (currentQuestionIndex !== questions.length - 1) {
            navigate(`/questionnaire/${questionnaireId}/${currentQuestionIndex + 1}`);
        }
    };

    const handleBackClick = () => {

        if (currentQuestionIndex !== 0) {
            const prevQuestion = questions[currentQuestionIndex - 1];
            if (prevQuestion.conditions && prevQuestion.conditions?.length > 0) {
                const { target, conditional, dependency, conditionCase } = prevQuestion.conditions[0];
                if (answers[dependency]?.answer !== conditionCase) {
                    navigate(`/questionnaire/${questionnaireId}/${currentQuestionIndex - 2}`);
                    return;
                }
            }
            navigate(`/questionnaire/${questionnaireId}/${currentQuestionIndex - 1}`);
        }

    };
    const {noNav} = CURRENTQUESTION;

    return (
        <AbsBG url={bgUrl} borderRadius="0px" opacity={bgOpacity}>
        <div className="" style={{ minHeight: "100vh", position: "relative",  }}>
            
            <Container className="orfeo-questionnare-bg pb-5" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Row style={{ flex: 1  }}>
                    <Col className="pt-4">
                        <ProgressBar now={progress} className="questionnaire-progress" /></Col>
                </Row>
                <Row style={{ flex: 6 }}>
                    <Col>
                        <h3 className="questionnaire-title">{title}</h3>
                        {CURRENTQUESTION.subTitle  ? <h4 className="questionnaire-subtitle">{CURRENTQUESTION?.subTitle}</h4> : null}
                        <div style={{ display: "flex", flexDirection: 'column' }}>
                            <Question question={CURRENTQUESTION} handleSubmit={handleSubmit} handleNextClick={handleNextClick} handleAnswerClick={handleAnswerClick} answers={answers} currentQuestionIndex={currentQuestionIndex} />
                        </div>
                    </Col>
                </Row>
                <Row style={{ position:"fixed", bottom:"20px", alignSelf: 'center', justifySelf: 'center' }}>
                    <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        {!noNav && <NextBtnGrp handleBackClick={handleBackClick} isLoading={isLoading} handleSubmit={handleSubmit} error={error} currentQuestionIndex={currentQuestionIndex} handleNextClick={handleNextClick} isComplete={isComplete} />}
                    </Col>
                </Row>
            </Container>
            
        </div>
        </AbsBG>
    );
};

export default Questionnaire;


