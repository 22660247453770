export default [
    {
        "id": "pcl-0",
        "title": "PCL-5 Please read each problem carefully and indicate how much you have been bothered by that problem in the past month.",
        "options": [],
        "type": "Text",
        "format": "",
        "values": [],
        "conditions": []
    },
    {
        "id": "pcl-1",
        "title": "How much were you bothered by repeated, disturbing, and unwanted memories of the stressful experience?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-2",
        "title": "How much were you bothered by repeated, disturbing dreams of the stressful experience? ",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-3",
        "title": "How often have you suddenly felt or acted as if the stressful experience were actually happening again (as if you were actually back there reliving it)?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-4",
        "title": "Have you felt very upset when something reminded you of the stressful experience",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-5",
        "title": "In the past month, did you have strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-6",
        "title": "Have you been avoiding memories, thoughts, or feelings related to the stressful experience?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-7",
        "title": "In the past month, have you been avoiding external reminders of the stressful experience (for example people, places, conversations, activities, objects or situations)?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-8",
        "title": "In the past month, did you have trouble remembering important parts of the stressful experience?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-9",
        "title": "In the past month have you been having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-10",
        "title": "In the past month, have you blamed yourself or someone else for the stressful experience or what happened after it?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-11",
        "title": "Were you having strong negative feelings such as fear, horror, anger, guilt or shame?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-12",
        "title": "In the past month, did you loose interest in activities that you used to enjoy?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-13",
        "title": "Have you been feeling distant or cut off from other people?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-14",
        "title": "Did you have trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-15",
        "title": "In the past month, did you have Irritable behavior, angry outbursts, or acting aggressively?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-16",
        "title": "Have you been taking too many risks or doing things that could cause you harm?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-17",
        "title": "In the past month, have you been “superalert” or watchful or on guard?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-18",
        "title": "Did you feel jumpy or easily startled?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-19",
        "title": "In the past month, have you been having difficulty concentrating?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    },
    {
        "id": "pcl-20",
        "title": "Did you have trouble falling or staying asleep?",
        "options": [
            "Not at all",
            "A little bit",
            "Moderately",
            "Quiet a bit",
            "Extremely"
        ],
        "type": "Likert",
        "format": "numerical",
        "values": [
            0,
            1,
            2,
            3,
            4
        ],
        "conditions": []
    }
];