import * as React from 'react';
// Import all plugins
import { createRoot } from "react-dom/client";
import {
  createHashRouter,
  HashRouter,
  RouterProvider,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import Navigation from '../../components/Navigation';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import Signup from '../../pages/Signup';

import PatientDashboard from '../../pages/PatientDashboard';


// Importing the Bootstrap CSS
import './app.scss';
import Questionnaire from '../../pages/Questionnaire';
import Session from '../../pages/Session';
import { AuthProvider } from '../../hooks/useAuth';

import Logo from './assets/mainnavlogo-01.webp'

const signupBg = require('./assets/backgrounds/sf-skyline-with-new-tower-2000x1000.jpg')


const root = document.getElementById("root") as HTMLElement;

createRoot(root).render(  
  <div style={{position: "relative"}}>
    <HashRouter>
      <AuthProvider>
        <Navigation logo={<img className="nav-logo-img" src={Logo} />} />
        
        <Routes>
          <Route path="/" element={<ProtectedRoute><PatientDashboard  bgOpacity={0.8}/></ProtectedRoute>} />
          <Route path="/questionnaire/:questionnaireId" element={<ProtectedRoute><Questionnaire bgOpacity={0.8} /></ProtectedRoute>} />
          <Route path="/session" element={<ProtectedRoute><Session /></ProtectedRoute>} />
          <Route path="/session/:sessionId" element={<ProtectedRoute><Session /></ProtectedRoute>} />
          <Route path="/questionnaire/:questionnaireId/:step" element={<ProtectedRoute><Questionnaire bgOpacity={0.8}/></ProtectedRoute>} />
          <Route path="/signup" element={<Signup bgOpacity={0.8} bgUrl={signupBg} />} />
        </Routes>
      </AuthProvider>
    </HashRouter>

  </div>
);