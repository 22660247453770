import * as React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({ children }) => {

  const { currentUser } = useAuth() as { currentUser: any };
  if (!currentUser ) {
    // user is not authenticated
    return <Navigate to="/signup" state={{"message": "Please login"}} />;
  }
  return children;
};

