import React from "react";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";

export const NextBtnGrp = ({ isLoading, handleBackClick, handleNextClick, handleSubmit, error, currentQuestionIndex, isComplete }: {
    handleBackClick: () => void;
    error?: string;
    currentQuestionIndex: number;
    handleNextClick: () => void;
    isComplete: boolean;
    isLoading: boolean;
    handleSubmit: () => void;
}) => {
    return <ButtonGroup className="p-2 questionnaire-btn-grp">
        <Button
            variant="success"
            onClick={handleBackClick}
            disabled={currentQuestionIndex === 0}
            style={{width:"150px"}}
        >
            Back
        </Button>
        <Button
            variant="success"
            onClick={isComplete ? handleSubmit : handleNextClick}
            style={{width:"150px"}}
        >
            {isComplete ? "Submit" : "Next"}  {isLoading && <Spinner animation="border" size="sm" className="mr-2" />}
        </Button>
    </ButtonGroup>;
};
