import React from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import FetchHealthRecords from "../components/FetchHealthRecords";
import BEAQ from "./measures/BEAQ";
import './player.css'

import PCL from "./measures/PCL";
import PHQ9 from "./measures/PHQ9";
import Young from "./measures/Young";
import GAD from "./measures/GAD";
import ACE from "./measures/ACE";
import SRS from "./measures/SRS";
import ORS from "./measures/ORS";
import TreatmentGoals from "./measures/TreatmentGoals";
import { ResponsivePlayer } from "../components/ResponsivePlayer";
import { BASE_URL } from "../config";


const questionnaires = {
    "BEAQ": BEAQ,
    "PCL": PCL,
    "PHQ-9": PHQ9,
    "young": Young,
    "GAD": GAD,
    "ACE": ACE,
    "SRS": SRS,
    "ORS": ORS,
    "TreatmentGoals": TreatmentGoals,
    "socio": [
        // {
        //     "id": "socio-0",
        //     "title": "Demographics",
        //     "options": [],
        //     "type": "",
        //     "min": "",
        //     "max": "",
        //     "placeholder": "",
        //     "format": "",
        //     "values": [],
        //     "conditions": []
        // },
        // {
        //     "id": "socio-1",
        //     "title": "Knowing a bit more about you will help us serve you better",
        //     "options": [],
        //     "type": "",
        //     "min": "",
        //     "max": "",
        //     "placeholder": "",
        //     "format": "",
        //     "values": [],
        //     "conditions": []
        // },
        {
            "id": "socio-2",
            "title": "Age",
            "options": [],
            "type": "number",
            "min": 18,
            "max": 100,
            "placeholder": "18",
            "format": "",
            "values": [],
            "conditions": []
        },
        {
            "id": "socio-3",
            "title": "Gender",
            "options": [
                "Female",
                "Male",
                "Decline to answer",
                "Other"
            ],
            "type": "select",
            "min": "",
            "max": "",
            "placeholder": "",
            "format": "",
            "values": [],
            "conditions": []
        },
        {
            "id": "socio-4",
            "title": "Other gender",
            "options": [],
            "type": "text",
            "min": "",
            "max": "",
            "placeholder": "",
            "format": "",
            "values": [],
            "conditions": [
                {
                    "target": "socio-4",
                    "conditional": true,
                    "dependency": "socio-3",
                    "conditionCase": "Other"
                }
            ]
        },
        {
            "id": "socio-5",
            "title": "Occupation",
            "options": [],
            "type": "text",
            "min": "",
            "max": "",
            "placeholder": "",
            "format": "",
            "values": [],
            "conditions": []
        },
        {
            "id": "socio-6",
            "title": "Income",
            "options": [],
            "type": "number",
            "min": "",
            "max": "",
            "placeholder": "",
            "format": "",
            "values": [],
            "conditions": []
        },
        {
            "id": "socio-7",
            "title": "Children",
            "options": [],
            "type": "number",
            "min": 0,
            "max": "",
            "placeholder": "",
            "format": "",
            "values": [],
            "conditions": []
        }

    ]
}


questionnaires["assessment"] = [
    {
        "id": "learning-0",
        "title": "Discover Hope with Psychedelic-Assisted Therapy",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
      
        "conditions": [],
        element: ({ onChange }) => {

            return <div>
                <p>Psychedelic-assisted therapy can help you process difficult emotions and experiences, reduce depression and anxiety symptoms, and increase feelings of connectedness.</p>
         
                <p>To get started with this type of therapy, you'll need to complete a brief assessment form that covers your demographic and medical history. Don't worry, our onboarding process is designed to be simple and straightforward, and we'll provide you with feedback within a few business days.</p>
                <p>If you're seeking help for mental health issues, psychedelic-assisted therapy may be a great option for you. Contact us today to learn more and get started on your journey towards healing and wellness.</p>

                <p><strong>Important Note:</strong> If you are experiencing a life-threatening emergency call the National Suicide Prevention Line immediately at <a href="tel:+18002738255">+1-800-273-8255</a>, dial <a href="tel:*988">*988</a>, or go to the <a href="https://www.google.com/maps/search/emergency+room/" target="_blank" rel="noopener noreferrer">nearest emergency room</a>.</p>
                {/* <ResponsivePlayer url={(new URL('../assets/video/d7ee5c4ca8224fb8b6f4c4d86f1020a0.mp4', import.meta.url)).href} /> */}
             
            </div>
        }
    },
    {
        "id": "Consent",
        "title": "Data Consent Policy",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        noNav: true,
        "conditions": [],
        element: ({ onChange, handleNextClick, answer }) => {

            return <div>
                 <p>This form is an agreement between Orfeo Health Incorporated and you, the participant, for the clinic-based ketamine depression therapy pilot program (referred to as "the Therapy").</p>
                    <p>By signing this form, you agree to the following:</p>
                    <ol>
                        <li>You will take part in the Therapy, and the Company will collect data related to the Therapy, such as clinical, demographic, and medical information ("Data").</li>
                        <li>The Company does not provide any medical or clinical services or care. They are solely responsible for collecting and analyzing the Therapy Data.</li>
                        <li>The Company will use your Therapy Data to develop mathematical and artificial intelligence models, evaluate the effectiveness of the Therapy, and improve their products and services for patients like you.</li>
                        <li>If you request it in writing, the Company will delete your Data. However, they will continue to use de-identified Therapy Data for development and improvement purposes.</li>
                        <li>You have the right to stop participating in the Therapy at any time. Simply notify the clinic where the Therapy is taking place.</li>
                        <li>You will not receive any payment or compensation for participating in the Therapy or for the use of your Therapy Data by the Company.</li>
                        <li>You waive any claim or right to any future innovations developed by the Company as a result of your participation in the Therapy.</li>
                        <li>By signing this form, you release the Company from any claims, losses, damages, liabilities, or demands related to your participation in the Therapy.</li>
                    </ol>
                    <a href=""> You can see the complete document here </a>
                <p>An email will be sent to you upon signing</p>

                <Button
                   variant={answer ? "success" : "primary"}
                    onClick={() => { 
                        fetch(`${BASE_URL}/consents`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                },
                            body: JSON.stringify({
                                "patient_id": localStorage.getItem('userId'),
                            })
                        }).finally(() => {
                            
                        onChange({"consent": true}) 
                        handleNextClick()
                        })

                    }}
                    className="questionnaire-option mb-5">
                     I consent
                </Button> 
               
            </div>
        }
    },
    ...questionnaires.socio,
    {
        "id": "treatment-goals-0",
        "title": "Charting Your Wellness Voyage",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        noNav: false,
        "conditions": [],
        element: ({ onChange, handleSubmit }) => {
           
            return <div>

               <h4>Treatment Goals Checklist</h4>
                {/* <ResponsivePlayer url={(new URL('../assets/video/treatment-goals.mp4', import.meta.url)).href}  width={80} height={80}/> */}
                    <p>
                        Setting goals isn't just a step - it's a milestone on your path to wellness in psychotherapy. 
                        We are eager to understand your personal aspirations for this journey. 
                    </p>
                    <p>
                        In a bid to make this process easier and more insightful for you, we've put together a comprehensive list.
                        This list is divided into five key sections, presenting broad areas on the left and specific therapy goals on the right.
                        It's our little guide to help you envision your journey and identify your stepping stones to wellness.
                    </p>
                    <ul>
                        <li>Immerse yourself in the list, appreciating each goal, and consider which ones resonate with your personal journey in therapy.</li>
                        <li>Should you have a unique goal that isn't found on our list, please feel empowered to express it in your own
                            words in the space provided.</li>
                        <li>Upon completing the list, take a moment to reflect on how each goal aligns with your life situation, 
                            and indicate the significance of each goal for you.</li>
                    </ul>
                    <p>
                        We are immensely grateful for your active involvement. Your insights not only aid your personal growth but also 
                        contribute to our understanding of how we can better support our patients' journeys.
                    </p>

                {/* <Button
                    variant={"primary"}
                    onClick={() => { handleSubmit('/questionnaire/assessmentTreatmentGoals/0') }}
                    className="questionnaire-option mb-5"
                >
                    Start Treatment Goals
                </Button> */}

            </div>
        },
    },
    ...questionnaires["TreatmentGoals"],
    {
        "id": "ace-0",
        "title": "Embracing the Tapestry of Our Lives",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        "conditions": [],
        noNav: false,
        element: ({ onChange, handleSubmit }) => {

            return <div>

                    <h4>Adverse Childhood Events</h4>
                    {/* <ResponsivePlayer url={(new URL('../assets/video/ace.mp4', import.meta.url)).href} width={80} height={80} /> */}

                    <p>
                        Our life's journey, encompassing our relationships and experiences - even those rooted in childhood - hold a profound impact on our wellness journey. Recognizing that challenging childhood experiences are a shared human reality, we invite you to open up about any such experiences from the list below.
                    </p>
                    
                    <p>
                        These experiences, though difficult, could be interwoven with your health today and might shape your future wellness path. Your courage in sharing this information acts as a beacon of hope, helping you and your care provider foster a collaborative approach to enhance your health and well-being.
                    </p>
                    <p>
                        Your personal journey not only propels your personal growth but contributes immensely to the broader healing community, supporting future wellness journeys.
                    </p>
            </div>

        }
    },
    ...questionnaires["ACE"],
   
    {
        "id": "phq-0",
        "title": "Embracing the Tapestry of Our Lives",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        "conditions": [],
        noNav: false,
        element: ({ onChange, handleSubmit }) => {
            return <div>

                    <h4>Unfolding Awareness: The PHQ-9 Path</h4>
                        
                        {/* <ResponsivePlayer url={(new URL('../assets/video/ace.mp4', import.meta.url)).href} width={80} height={80} />
                                         */}
                    <p>
                    The PHQ-9, a depression screening tool, is a guide to understanding your mental well-being. It encourages you to reflect on your feelings and behaviors, such as changes in appetite or energy. This tool aids in identifying symptoms of depression, like ongoing sadness or reduced interest in favorite activities.
                    </p>
                    <p>
                    The PHQ-9 is not just a tool, but an empowering companion. It offers indications that may guide you to seek professional mental health support when needed. Your participation not only aids your own wellness journey but also contributes to the broader understanding that can help others.
                    </p>
               
            </div>
        }
    },  
    ...questionnaires["PHQ-9"],
    {
        "id": "intro-gad",
        "title": "Embracing the Tapestry of Our Lives",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        "conditions": [],
        element: ({ onChange, handleSubmit }) => {
        
            return <div>

                    <h4>GAD-7: Unraveling Anxiety's Threads</h4>
                        
                        {/* <ResponsivePlayer url={(new URL('../assets/video/ace.mp4', import.meta.url)).href} width={80} height={80} /> */}
                                        
                                                
                        <p>
                        The GAD-7 instrument serves as a lens to scrutinize your mental wellness, honing in specifically on aspects of anxiety. By gently prompting introspection about certain patterns of thought and behavior, it helps highlight potential markers of generalized anxiety disorder such as unceasing worry or a sense of restlessness.
                        </p>
                        <p>
                        More than a simple tool, the GAD-7 is a strategic ally on your wellness journey. It offers valuable insights that assist you in understanding when additional professional support may be beneficial. Each time you engage with it, you're contributing to a deeper communal understanding of anxiety and mental health.
                        </p>


               
            </div>
        }
    },
    ...questionnaires["GAD"],
    {
        "id": "intro-pcl",
        "title": "Embracing the Tapestry of Our Lives",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        "conditions": [],
        noNav: false,
        element: ({ onChange, handleSubmit }) => {
   
            return <div>
                <h4>The PTSD Checklist (PCL-5)</h4>
    
                        
                        {/* <ResponsivePlayer url={(new URL('../assets/video/ace.mp4', import.meta.url)).href} width={80} height={80} />
                                         */}
                                                
                        <p>The PCL-5 is a questionnaire that helps us better understand symptoms related to post-traumatic stress disorder, or PTSD. The questions will focus on experiences you might have had over the past month.</p>
                        <p>The information you provide plays a significant role in your treatment progress. This questionnaire is made up of 20 questions, and your answers will help us determine the best way to support your mental well-being.</p>
                        <p>There's no need to worry about getting any answers "right" or "wrong." Every individual's experience is unique, and all information is valuable. Your honesty will guide us in tailoring a treatment plan that fits you best.</p>
                        <p>This tool is part of our commitment to your health, and we're here to support you every step of the way.</p>
                        
                    {/* <Button
                        variant={"primary"}
                        onClick={() => { handleSubmit('/questionnaire/assessment-PCL/0') }}
                        className="questionnaire-option mb-5"
                    >
                        Start
                    </Button>    */}
               
            </div>
        }
    },
    ...questionnaires["PCL"],
    {
        "id": "assessment-finished",
        "title": "Next steps",
        "options": [],
        "type": "element",
        "format": "",
        "values": [],
        "conditions": [],
        noNav: true,
        element: ({ onChange, handleSubmit }) => {
   
            return <div>
               <h4>Congrats!</h4>
                <p>
                Congratulations on completing your questionnaires! By doing so, you've taken an essential step on your mental health journey, demonstrating an admirable commitment to self-understanding.
                </p>
                <p>
                Your contributions are invaluable and greatly appreciated. With your completed responses, we are better equipped to provide the supportive care you deserve.
                </p>
                <p>
                In terms of next steps, expect to hear from a member of our team soon. They will provide further guidance and assistance on your path forward.
                </p>
                <p>
                Don't forget, you can check your results on the dashboard at any time. It's your personal space to stay updated about this journey.
                </p>
                    <Button
                        variant={"primary"}
                        onClick={() => { handleSubmit('/') }}
                        className="questionnaire-option mb-5"
                    >
                        Start
                    </Button>   
               
            </div>
        }
    },

]


questionnaires["self_manage"] = [ // Formal Diagnosis, Are you taking any other medications? Type in name 
    {
        "id": "self-manage-0",
        "title": "Self manager",
        "options": [
            "Strongly disagree",
            "Disagree",
            "Slightly disagree",
            "Slightly agree",
            "Agree",
            "Strongly agree"
        ],
        "type": "Likert",
        "values": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "conditions": []
    },
]


questionnaires["initial"] = [
    {
        id: "intro", title: "Welcome to the Orfeo Pilot",
         options: [], type: "element", values: [], conditions: [], 
         noNav: true, 
         element: ({ onChange, handleSubmit }) => {

            return <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                                    
                        <p>We're genuinely grateful that you've decided to join us at Derive and Orfeo Health in this new endeavor. Your choice to participate in this innovative protocol is a courageous step towards advancing mental healthcare, not only for yourself, but for others as well.</p>
                        <p>This journey we're embarking on is in close cooperation with your dedicated team at Derive. Together, our aim is to supplement your current treatment plan, offering a more nuanced approach tailored to your specific needs.</p>
                        <p>Your insights and experiences are invaluable to us. They will guide us in refining our strategies and broadening our knowledge in this field, ultimately helping us assist others in their mental health journey.</p>
                        <p>While we're excited about the potential impact of this protocol, we're also humble in recognizing that our progress is due in large part to participants like you. It is your courage and commitment that truly drive this initiative forward.</p>
                        <p>So thank you once again, and welcome to this collaborative endeavor. Together, we are making strides towards improved mental health outcomes for all.</p>

                    
                    <Button
                        variant={"primary"}
                        onClick={() => { 
                            handleSubmit('/questionnaire/assessment') }}
                        className="questionnaire-option mb-5"
                    >
                        Get Started 
                    </Button>
                    {/* <p>
                        If you are already a patient, please click below to safely manage your sessions.
                    </p>
                    <Button
                        variant={"primary"}
                        onClick={() => { navigate('/questionnaire/self_manage') }}
                        className="questionnaire-option mb-5"
                    >
                       Safely manage my sessions
                    </Button> */}
                </div>

            </div>

        }
    },

    // { id: "medical-records", title: "Connect Medical Records (Optional)", options: [], type: "element", values: [], conditions: [], element: ({ onChange }) => <Container><Row><Col><FetchHealthRecords onConnect={onChange} /> </Col></Row > </Container> },

]

questionnaires["session_followup"] = [
    ...ORS,
    ...SRS
]

export default questionnaires;

const processQ = (qqq, prefix) => Object.keys(qqq)
    .map((i) => qqq[i])
    // .filter((x) => x.length > 1)
    .map((q) => {
        return {
            id: `${prefix}-${q[0].Id}`,
            title: q[0].Description,
            options: q.filter(qq => qq.Option !== "").map(qq => qq.Option),
            type: q[0].Type,
            min: q[0].Min,
            max: q[0].Max,
            placeholder: q[0].Placeholder,
            format: q[0].Format,
            values: q.filter(qq => qq.Score !== "").map(qq => qq.Score),
            conditions: q.filter(qq => qq.Conditional).map(qq => { return { target: `${prefix}-${q[0].Id}`, conditional: qq.Conditional, dependency: `${prefix}-${qq.ConditionDependency}`, conditionCase: qq.ConditionCase } })

        }
    })